import { useField } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FieldHookConfig } from 'formik/dist/Field';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import { FieldCheckIcon } from '../../CheckIcon';
import { ValidationSchemaProps } from '../typings';

export const initialValue = '';

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .string()
    .required(
      intl.formatMessage({
        id: 'form.validation.required',
        defaultMessage: 'This field is required',
      })
    )
    .min(
      6,
      intl.formatMessage(
        {
          id: 'form.validation.min',
          defaultMessage: 'The minimum field length is {value}',
        },
        { value: 5 }
      )
    );

export function Component(
  props: FieldHookConfig<string> & {
    defaultMessage: string;
    showError?: boolean;
  }
) {
  const { defaultMessage, showError, ...otherProps } = props;
  const [field, meta] = useField(otherProps);
  const intl = useIntl();
  const isError = Boolean(meta.touched && meta.error && showError);

  return (
    <FormControl fullWidth variant="outlined" error={isError}>
      <OutlinedInput
        {...field}
        fullWidth
        type="password"
        placeholder={intl.formatMessage({
          id: `form.${props.name}`,
          defaultMessage,
        })}
        endAdornment={
          <InputAdornment position="end">
            <FieldCheckIcon meta={meta} />
          </InputAdornment>
        }
      />
      {isError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
