import { IntlShape } from 'react-intl';
import {
  ROUTE_PRIZE_DRAWING,
  ROUTE_RATING,
  ROUTE_TASKS,
  ROUTE_REFERRALS,
  ROUTE_GAMES,
} from 'src/constants/routes';

import { ReactComponent as MenuIconHome } from 'src/assets/icons/home.svg';
import { ReactComponent as MenuIconPrizes } from 'src/assets/icons/prizes.svg';
import { ReactComponent as MenuIconTasks } from 'src/assets/icons/tasks.svg';
import { ReactComponent as MenuIconReferrals } from 'src/assets/icons/referrals.svg';
import { ReactComponent as MenuIconGames } from 'src/assets/icons/gamepad.svg';
import { FEATURE } from 'src/constants/enums';

interface CreateMenuItemsParams {
  intl: IntlShape;
  withExtraFeatures?: boolean;
}

type InitMenu = (params: CreateMenuItemsParams) => Array<MenuItemProps>;

enum MenuHoverEffect {
  fill = 'fill',
  stroke = 'stroke',
}

const initMenu: InitMenu = ({ intl }) => [
  {
    text: intl.formatMessage({
      id: 'menu.mainpage',
      defaultMessage: 'Main page',
    }),
    to: '/',
    icon: <MenuIconHome />,
    hoverEffect: MenuHoverEffect.stroke,
    forMobile: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.prize',
      defaultMessage: 'Prize drawing',
    }),
    to: ROUTE_PRIZE_DRAWING,
    icon: <MenuIconPrizes />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.tasks',
      defaultMessage: 'tasks',
    }),
    to: ROUTE_TASKS,
    icon: <MenuIconTasks />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: false,
    needAuth: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.rating',
      defaultMessage: 'Rating',
    }),
    to: ROUTE_RATING,
    icon: <MenuIconPrizes />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: false,
    needAuth: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.games',
      defaultMessage: 'Games',
    }),
    to: ROUTE_GAMES,
    icon: <MenuIconGames />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: true,
    accessBy: [FEATURE.SLOT_MACHINE, FEATURE.WHEEL_OF_FORTUNE],
    needAuth: true,
  },
  {
    text: intl.formatMessage({
      id: 'menu.referrals',
      defaultMessage: 'Referrals',
    }),
    to: ROUTE_REFERRALS,
    icon: <MenuIconReferrals />,
    hoverEffect: MenuHoverEffect.fill,
    forMobile: true,
    needAuth: true,
  },
];

export default initMenu;
