import Box from '@mui/material/Box';
import useRequest from 'src/hooks/useRequest';
import Response from 'src/components/Response';
import { RemoteDataResult } from 'src/lib/remoteData';
import { useLocaleState } from 'src/providers/LocaleProvider';

interface DocumentPageProps {
  service: (lang: string) => Promise<RemoteDataResult<Page>>;
}

export default function DocumentPage({ service }: DocumentPageProps) {
  const locale = useLocaleState();
  const [request] = useRequest(async () => await service(locale));

  return (
    <Response request={request}>
      {(data) => (
        <Box
          className="ignore_rtl"
          sx={{
            maxWidth: '100%',
            overflowX: 'auto',
            paddingX: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: data.text,
          }}
        />
      )}
    </Response>
  );
}
