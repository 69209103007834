import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';

export interface WheelItem {
  id: number;
  amount: number;
  name: string;
  win_text: number;
}

export interface WheelResult {
  spins: number;
  new_spin_at: number;
  list: WheelItem[];
}

export async function getItems(): Promise<RemoteDataResult<WheelResult>> {
  return request({
    url: `v1/wheel_of_fortune/`,
    method: 'GET',
  });
}

interface WheelWinner {
  win_id: number;
  spins: number;
  new_spin_at: number;
  list: WheelItem[];
}

export async function getItem({
  route,
  origin,
}: {
  origin?: string;
  route: string;
}): Promise<RemoteDataResult<WheelWinner>> {
  return request({
    url: `v1/wheel_of_fortune/spin`,
    method: 'POST',
    data: JSON.stringify({ origin, route }),
  });
}

export const getSlotMachineSymbols = () => {
  return request({
    url: `v1/slot/symbols`,
    method: 'GET',
  });
};

type SpinResponse = {
  balance: number;
  bet: number;
  win: number;
  win_matrix: number[][];
  matrix: string[][];
};

export const spinSlotMachine = (bet: number) => {
  return request<SpinResponse>({
    url: `v1/slot/spin`,
    method: 'POST',
    data: JSON.stringify({ bet }),
  });
};
