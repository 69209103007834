import { FunctionComponent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Modal from '../Modal';
import { useSlotBalanceStyles, useSlotWinModalStyles } from './get-styles';
import { mergeClasses } from '@griffel/react';

type Props = {
  value?: number;
  onClose: () => void;
};

const WinModal: FunctionComponent<Props> = ({ value, onClose }) => {
  const intl = useIntl();
  const styles = useSlotWinModalStyles();
  const { balance, balanceLarge } = useSlotBalanceStyles();
  const balanceStyles = mergeClasses(balance, balanceLarge);

  return (
    <Modal
      isOpen={true}
      handleClose={onClose}
      withClose
      title={intl.formatMessage({
        id: 'prize_modal.congratulate',
        defaultMessage: 'Congratulate',
      })}
    >
      <div className={styles.title}>
        {intl.formatMessage({
          id: 'slot_machine.won_message',
          defaultMessage: 'You won',
        })}
      </div>
      <div className={styles.prize}>
        <div className={balanceStyles}>{value}</div>
      </div>
    </Modal>
  );
};

export default WinModal;
