import * as Sentry from '@sentry/react';

interface Storage {
  getItem(key: string): string | null;
  setItem(key: string, value: any): void;
  removeItem(key: string): void;
}

const CHECK_KEY = '__foo';
const CHECK_VALUE = '__bar';

const canStorageBeUsed = (
  storageName: 'localStorage' | 'sessionStorage'
): boolean => {
  try {
    const s: Storage = window[storageName];
    s.setItem(CHECK_KEY, CHECK_VALUE);
    s.getItem(CHECK_KEY);
    s.removeItem(CHECK_KEY);
    return true;
  } catch (e) {
    console.warn(`${storageName} can't be used`, e);
    return false;
  }
};

class WindowStorage implements Storage {
  protected values: Record<string, any>;

  constructor() {
    this.values = {};
  }
  get length() {
    return Object.keys(this.values).length;
  }
  key(index: number) {
    return Object.keys(this.values)[index];
  }
  getItem(key: string): string | null {
    return this.values[key] || null;
  }
  setItem(key: string, value: any) {
    this.values[key] =
      typeof value !== 'string' ? JSON.stringify(value) : value;
  }
  removeItem(key: string): void {
    delete this.values[key];
  }
  clear() {
    Object.keys(this.values).forEach((key) => {
      this.removeItem(key);
    });
  }
}

let storage: Storage;

if (window) {
  if (canStorageBeUsed('localStorage')) {
    storage = window.localStorage;
  } else if (canStorageBeUsed('sessionStorage')) {
    storage = window.sessionStorage;
  } else {
    storage = new WindowStorage();
  }
} else {
  storage = new WindowStorage();
}

export function set(key: string, value: any) {
  storage.setItem(key, JSON.stringify(value));
}

export function reset(key: string) {
  storage.removeItem(key);
}

export function get(key: string, defaultValue: any = null) {
  try {
    const value = storage.getItem(key);

    if (value === null) {
      return defaultValue;
    }

    return JSON.parse(value);
  } catch (err) {
    console.warn(err);
    return defaultValue;
  }
}
