import { FunctionComponent, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import LayoutBase from 'src/layouts/LayoutBase';
import WithHeroColumn from 'src/layouts/WithHeroColumn';
import signupImage from 'src/layouts/assets/frame-sign.png';
import Container from '@mui/material/Container';

const LayoutSignUpStandard: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const intl = useIntl();

  return (
    <LayoutBase>
      <WithHeroColumn
        title={intl.formatMessage({
          id: 'registation_page.title',
          defaultMessage: 'Hello!',
        })}
        subtitle={intl.formatMessage({
          id: 'registation_page.subtitle',
          defaultMessage: 'Sign up and get a ticket for a prize drawing',
        })}
        image={signupImage}
        imageSize="cover"
      >
        <Container maxWidth="xs">{children}</Container>
      </WithHeroColumn>
    </LayoutBase>
  );
};

export default LayoutSignUpStandard;
