import * as yup from 'yup';
import {
  FormEvent,
  FunctionComponent,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { IntlShape, useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as gtm from 'src/lib/gtm';
import * as BirthdayFields from './fields/BirthdayFields';
import * as FirstNameField from './fields/FirstNameField';
import * as LastNameField from './fields/LastNameField';
import * as EmailField from './fields/EmailField';
import * as HiddenField from './fields/HiddenField';
import * as GenderField from './fields/GenderField';
import * as PhoneField from './fields/PhoneField';
import * as PasswordField from './fields/PasswordField';
import * as RecaptchaV2Field from './fields/RecaptchaV2Field';
import * as RecaptchaV3Field from './fields/RecaptchaV3Field';
import * as PrivacyAgreementField from './fields/PrivacyAgreement/PrivacyAgreementField';
import * as ZipFields from './fields/ZipFields';
import * as CustomCaptchaField from './fields/CustomCaptchField';
import * as PrivacyAgreementModalField from './fields/PrivacyAgreement/ModalField/PrivacyAgreementModalField';
import * as CloudflareCaptchaField from './fields/CloudflareCaptchaField';
import { FieldFactory, FieldName } from './typings';
import { RegFormStorageService, SignupBody } from 'src/services/AuthService';
import useCaptchaAwait from 'src/hooks/useRecaptchAwait';
import { useGoogleAuthDispatch } from 'src/providers/GoogleAuthProvider';
import GoogleButtonStandard from 'src/components/buttons/GoogleButtonStandard';
import FacebookButton from 'src/components/buttons/FacebookButton';
import { createErrorContainer, ErrorContainer } from 'src/lib/errors';
import { useTimestampFirstInteraction } from './useTimestampFirstInteraction';
import { SMARTLOOK_CUSTOM_EVENT } from 'src/constants/enums';
import { smartlookTrackEvent } from 'src/services/GoogleTagManagerService';
import { DESIGN_ID } from 'src/constants/landings';
import { modalAgreementFieldsWithCaptcha } from 'src/components/forms/RegistationForm/contstants';

import { useReCaptchaContext } from 'src/providers/ReCaptchaProvider';
import { EXTERNAL_AUTH, useExternalAuthProfile } from 'src/recoil/externalAuth';
import UserFields from 'src/components/forms/RegistationForm/UserFields';

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

const FieldFactories: Record<FieldName, FieldFactory> = {
  first_name: FirstNameField,
  last_name: LastNameField,
  email: EmailField,
  password: PasswordField,
  confirm_password: PasswordField,
  phone: PhoneField,
  gender: GenderField,
  postcode: ZipFields,
  birth_date: BirthdayFields,
  captchaV2: RecaptchaV2Field,
  captchaV3: RecaptchaV3Field,
  privacy_agreement: PrivacyAgreementField,
  custom_captcha: CustomCaptchaField,
  privacy_agreement_modal: PrivacyAgreementModalField,
  city: HiddenField,
  terms: HiddenField,
  chosen_prize: HiddenField,
  cloudflare_captcha: CloudflareCaptchaField,
};

interface ValidationSchemaProps {
  intl: IntlShape;
}

const createFormInitialValues = (fieldNames: FieldName[]) => {
  const values: PartialRecord<FieldName, any> = {};

  fieldNames.forEach((fieldName) => {
    if (fieldName in FieldFactories) {
      const name = (FieldFactories[fieldName].name ?? fieldName) as FieldName;

      values[name] = FieldFactories[fieldName].initialValue;
    }
  });

  return values;
};

export const createValidationSchema = (
  fieldNames: FieldName[],
  props: ValidationSchemaProps
) => {
  const validationSchema: PartialRecord<FieldName, any> = {};

  fieldNames.forEach((fieldName) => {
    if (fieldName in FieldFactories) {
      const name = (FieldFactories[fieldName].name ?? fieldName) as FieldName;

      validationSchema[name] =
        FieldFactories[fieldName].createValidationSchema(props);
    }
  });

  return yup.object(validationSchema);
};

interface RegistrationFormProps {
  abExperimentId?: number;
  designId: number;
  fields: FieldName[];
  blacklist?: string[];
  extInitialValues?: PartialRecord<FieldName, string | boolean | number>;
  recaptchaVersion?: RecaptchaVersion;
  handleMarketingClick: () => void;
  handleTermsClick: () => void;
  handlePrivacyClick: () => void;
  handleErrorsChange: (errors: any, errorContainer?: ErrorContainer) => void;
  handleSubmit: (
    values: SignupBody & { captchaV2?: string; captchaV3?: string }
  ) => Promise<void>;
  onFirstInteraction?: (timestamp: number) => void;
  internalAuthButtons: {
    fb: boolean;
    google: boolean;
  };
}

const FieldRow: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <Box sx={{ marginY: 2 }}>{children}</Box>;
};

type FormValues = PartialRecord<FieldName, any>;

const Separator = memo(() => {
  const intl = useIntl();

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        marginY: 3,
        textAlign: 'center',
        position: 'relative',
        fontSize: '16px',
        textTransform: 'lowercase',
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '50%',
          width: 'calc(50% - 25px)',
          height: '1px',
          background: theme.palette.text.disabled,
        },
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: 'calc(50% - 25px)',
          right: 0,
          top: '50%',
          height: '1px',
          background: theme.palette.text.disabled,
        },
      })}
    >
      {intl.formatMessage({ id: 'sign_up.or', defaultMessage: 'Or' })}
    </Box>
  );
});

function RegistrationForm({
  abExperimentId,
  fields,
  extInitialValues,
  blacklist = [],
  recaptchaVersion,
  handleSubmit,
  handleErrorsChange,
  handleTermsClick,
  handlePrivacyClick,
  handleMarketingClick,
  onFirstInteraction,
  designId,
  internalAuthButtons,
}: RegistrationFormProps) {
  const intl = useIntl();
  const validationProps = { intl, blacklist, recaptchaVersion };
  const externalAuthProfile = useExternalAuthProfile();

  const needV3 = fields.includes('captchaV3') && recaptchaVersion === 3;
  const needCloudflare = fields.includes('cloudflare_captcha');
  const needCaptchaWidget = needV3 || needCloudflare;

  const { readyToInit } = useReCaptchaContext();

  const initialValues = useMemo(
    () =>
      RegFormStorageService.data ?? {
        ...createFormInitialValues(fields),
        ...extInitialValues,
      },
    [fields]
  );

  const validationSchema = useMemo(
    () => createValidationSchema(fields, validationProps),
    [fields, validationProps]
  );

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, helpers) =>
      handleSubmit({
        ...values,
        age: values?.custom_captcha?.result,
      }),
  });
  const { login } = useGoogleAuthDispatch(
    69 === abExperimentId || DESIGN_ID.HIDE_GOOGLE_AUTH_FULL_DATA === designId,
    formik.values
  );
  useEffect(() => {
    if (externalAuthProfile?.type === EXTERNAL_AUTH.GOOGLE) {
      const { last_name, ...rest } = externalAuthProfile.data;
      const updated = {
        ...formik.values,
        ...rest,
        last_name: last_name?.length > 0 ? last_name : formik.values?.last_name,
      };
      formik.setValues(updated);
      setTimeout(() => {
        formik.setTouched(updated);

        [DESIGN_ID.HIDE_GOOGLE_AUTH_FULL_DATA, DESIGN_ID.SHORT_FORM].includes(
          designId
        ) &&
          formik.setTouched(
            modalAgreementFieldsWithCaptcha.reduce(
              (acc, value) => ({ ...acc, [value]: true }),
              {}
            )
          );
        if (designId === DESIGN_ID.SHORT_FORM) {
          formik.setSubmitting(true);
          next();
        }
      });
    }
    if (externalAuthProfile?.type === EXTERNAL_AUTH.FB) {
      const data = externalAuthProfile.data;

      formik.setValues({ ...formik.values, ...data });

      setTimeout(() => {
        formik.setTouched(data);
      });
    }
  }, [externalAuthProfile]);

  useEffect(() => {
    if (formik.values.gender === 1) {
      gtm.set('gender', 'male');
    }
    if (formik.values.gender === 2) {
      gtm.set('gender', 'female');
    }
  }, [formik.values.gender]);

  useTimestampFirstInteraction(
    initialValues,
    formik.values,
    onFirstInteraction || (() => {})
  );

  const submitForm = useCallback(async () => {
    if (Object.keys(formik.errors).length) {
      let errorContainer;
      try {
        await validationSchema.validate(formik.values, {
          abortEarly: false,
        });
      } catch (err) {
        errorContainer = createErrorContainer(err as yup.ValidationError);
      }
      const errors = Object.entries(formik.errors)
        .filter(([key, _]) => key !== 'captchaV3')
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      handleErrorsChange(errors, errorContainer);
    }
    formik.handleSubmit();
  }, [
    formik.errors,
    formik.handleSubmit,
    formik.values,
    validationSchema,
    handleErrorsChange,
  ]);

  const submitManually = () => {
    formik.setSubmitting(true);
    handleSubmit({
      ...formik.values,
      age: formik.values?.custom_captcha?.result,
    });
  };

  const next = useCaptchaAwait(needCaptchaWidget, () => {
    if (RegFormStorageService.hasData) {
      submitManually();
      RegFormStorageService.clear();
    } else {
      submitForm();
    }
  });

  const handleSubmitForm = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if ((needV3 && !readyToInit) || needCloudflare) {
        submitForm();
        return;
      }
      smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.SIGNUP_BUTTON_CLICK);
      formik.setSubmitting(true);
      next();
    },
    [submitForm]
  );

  useEffect(() => {
    if (RegFormStorageService.hasData) {
      next();
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      <form onReset={formik.handleReset} onSubmit={handleSubmitForm}>
        <HiddenField.Component
          position="zIndex"
          name="chosen_prize"
          type="text"
        />
        <HiddenField.Component position="margin" name="terms" type="checkbox" />
        {internalAuthButtons.google && (
          <Box
            sx={{
              marginBottom: 2,
              width: '100%',
            }}
          >
            <GoogleButtonStandard onClick={login} isMobile />
          </Box>
        )}
        {internalAuthButtons.fb && (
          <Box
            sx={{
              marginBottom: 2,
              width: '100%',
            }}
          >
            <FacebookButton isMobile extraData={formik.values} />
          </Box>
        )}
        {designId === DESIGN_ID.SHORT_FORM && <Separator />}
        <UserFields fields={fields} />
        {fields.includes('captchaV2') && recaptchaVersion === 2 ? (
          <RecaptchaV2Field.Component
            isSubmitting={formik.isSubmitting}
            name="captchaV2"
          />
        ) : null}
        {needV3 ? (
          <RecaptchaV3Field.Component
            name="captchaV3"
            action="signin"
            callback={next}
          />
        ) : null}
        {fields.includes('custom_captcha') && recaptchaVersion === 3 ? (
          <CustomCaptchaField.Component name="custom_captcha" />
        ) : null}
        {fields.includes('privacy_agreement') && (
          <PrivacyAgreementField.Component
            name="privacy_agreement"
            onTermsLinkClick={handleTermsClick}
            onPrivacyLinkClick={handlePrivacyClick}
            onMarketingLinkClick={handleMarketingClick}
          />
        )}
        {needCloudflare && (
          <CloudflareCaptchaField.Component
            name="cloudflare_captcha"
            callback={next}
          />
        )}
        {fields?.includes('privacy_agreement_modal') && (
          <PrivacyAgreementModalField.Component
            name="privacy_agreement"
            onMarketingLinkClick={handleMarketingClick}
            onPrivacyLinkClick={handlePrivacyClick}
            onTermsLinkClick={handleTermsClick}
          />
        )}
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: 1,
            marginTop: 2,
            flexWrap: 'wrap',
            '& button': {
              flex: '0 auto',
              flexBasis: '100%',
            },
          })}
        >
          <Button
            size="large"
            disabled={formik.isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
            id="signup_button"
            sx={{ flex: 1 }}
            onClick={handleSubmitForm}
            data-reg-popup
          >
            {formik.isSubmitting ? (
              <CircularProgress style={{ color: 'white' }} size={26} />
            ) : (
              intl.formatMessage({
                id: 'create_account',
                defaultMessage: 'Create an account',
              })
            )}
          </Button>
          {internalAuthButtons.google && (
            <GoogleButtonStandard onClick={login} />
          )}
          {internalAuthButtons.fb && (
            <FacebookButton extraData={formik.values} />
          )}
        </Box>
      </form>
    </FormikProvider>
  );
}

export default memo(RegistrationForm);
