import { RemoteDataResult } from 'src/lib/remoteData';
import { request } from 'src/lib/request';

export const getTranslate = async (
  lang: string
): Promise<RemoteDataResult<Record<string, string>>> => {
  return await request(
    { method: 'get', url: '/v1/translate', params: { lang } },
    (response) => response.data
  );
};
