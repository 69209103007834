import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { FieldHookConfig, useField } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as yup from 'yup';

import Modal from 'src/components/Modal';
import {
  PrivacyAgreementFieldProps,
  REJECTED_ANY,
} from '../PrivacyAgreementField';
import PrivacyAgreementField from './PrivacyAgreementField';
import TCSettings from 'src/components/TermsConditionSettings/TCSettings';
import PrivacyAgreementModalText from './PrivacyAgreementModalText';
import { ValidationSchemaProps } from 'src/components/forms/RegistationForm/typings';

export const initialValue = false;
export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup
    .boolean()
    .required(
      intl.formatMessage({
        id: 'form.validation.required.privacy_agreement',
        defaultMessage: 'This field is required',
      })
    )
    .oneOf(
      [true],
      intl.formatMessage({
        id: 'form.validation.required.privacy_agreement',
        defaultMessage: 'This field is required',
      })
    );

export const name = 'privacy_agreement';

export const Component: FunctionComponent<
  PrivacyAgreementFieldProps & FieldHookConfig<boolean>
> = ({ onPrivacyLinkClick, onTermsLinkClick, onMarketingLinkClick, name }) => {
  const [isOpen, setOpen] = useState(true);
  const [isShowSettings, setShowSettings] = useState(false);
  const [iShowCheckbox, setShowCheckbox] = useState(false);
  const [, , helper] = useField(REJECTED_ANY);
  const [field, meta, mainFieldHelper] = useField(name);
  const intl = useIntl();

  const settingsCallback = (
    isRejectedAny: boolean,
    isRejectedAll?: boolean
  ) => {
    setOpen(false);
    if (isRejectedAll) {
      setShowCheckbox(true);
    } else {
      mainFieldHelper.setValue(true);
    }

    helper.setValue(isRejectedAny || undefined);
  };

  return (
    <>
      {iShowCheckbox && (
        <PrivacyAgreementField
          field={field}
          meta={meta}
          onPrivacyLinkClick={onPrivacyLinkClick}
          onTermsLinkClick={onTermsLinkClick}
        />
      )}
      <Modal
        isOpen={isOpen}
        handleClose={() => {}}
        fullWidth
        title={intl.formatMessage({
          id: 'tc_settings_modal.header',
          defaultMessage: 'Advertising Media Channels Settings',
        })}
        titleSx={{ textAlign: 'center' }}
      >
        {isShowSettings ? (
          <>
            <TCSettings
              onSave={settingsCallback}
              handleMarketingClick={onMarketingLinkClick}
            />
            <Button
              sx={{
                marginTop: 2,
              }}
              onClick={() => settingsCallback(true, true)}
              color="secondary"
              fullWidth
            >
              {intl.formatMessage({
                id: 'tc_settings_modal.reject_all',
                defaultMessage: 'Reject all',
              })}
            </Button>
          </>
        ) : (
          <Box
            sx={(theme) => ({
              color: theme.palette.text.disabled,
              [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: 1,
                textAlign: 'justify',
              },
            })}
          >
            <PrivacyAgreementModalText
              onMarketingLinkClick={onMarketingLinkClick}
              onOpenSettingsClick={() => setShowSettings(true)}
              onTermsLinkClick={onTermsLinkClick}
              onPrivacyLinkClick={onPrivacyLinkClick}
            />
            <Button
              sx={{
                marginY: 2,
              }}
              onClick={() => settingsCallback(false)}
              color="primary"
              variant="contained"
              fullWidth
            >
              {intl.formatMessage({
                id: 'tc_settings_modal.save_all',
                defaultMessage: 'Accept all and continue',
              })}
            </Button>
            <Button
              onClick={() => setShowSettings(true)}
              color="secondary"
              fullWidth
            >
              {intl.formatMessage({
                id: 'tc_settings_modal.settings',
                defaultMessage: 'Settings',
              })}
            </Button>
          </Box>
        )}
      </Modal>
    </>
  );
};
