import { FunctionComponent, useEffect, useLayoutEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import {
  useProfileDispatch,
  useProfileState,
} from 'src/providers/ProfileProvider';
import { getRegistrationUrlParams } from 'src/lib/url';
import useCheckCurrentUser from './useCheckCurrentUser';
import Loader from 'src/components/Loader';
import InAppOverlay from "src/components/InAppOverlay/InAppOverlay";

const Logout = () => {
  const { logout } = useProfileDispatch();

  useLayoutEffect(() => {
    logout().then();
  }, []);

  return <></>;
};

const CheckOaid = () => {
  const result = useCheckCurrentUser();

  if (result === null) {
    return <Loader fullSize />;
  }
  if (!result) {
    return <Logout />;
  }
  return <Navigate to="/" replace />;
};

const LayoutPreSignup: FunctionComponent<{ isAuth?: boolean }> = ({}) => {
  const { profile } = useProfileState();
  const isAuth = !!profile;
  const params = getRegistrationUrlParams();

  if (isAuth) {
    if (params.design_id && params.sub_id1 && params.sub_id5) {
      return <CheckOaid />;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  return <>
    <InAppOverlay />
    <Outlet />
  </>;
};

export default LayoutPreSignup;
