// @ts-nocheck
import { useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import * as Sentry from '@sentry/react';
import { NOTIX_APP_ID } from 'src/constants/ids';
import { useFeature } from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import { PLACES_API_KEY } from 'src/constants/app';

const NOTIX_SCRIPT_SOURCE = 'https://notix.io/ent/current/enot.min.js';

const loadScriptByUrl = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (!response.ok) {
      Sentry.captureException(`loading script error: ${response.status}`);
      return;
    }

    const scriptContent = await response.text();
    const scriptElement = document.createElement('script');
    scriptElement.textContent = scriptContent;
    document.head.appendChild(scriptElement);
  } catch (e) {
    console.error(e);
  }
};

export const setupNotix = (settings: object = {}) => {
  if (!NOTIX_APP_ID) {
    return;
  }
  const scriptElement = document.createElement('script');

  scriptElement.src = NOTIX_SCRIPT_SOURCE;
  scriptElement.onload = function (sdk: any) {
    sdk.startInstall({
      appId: NOTIX_APP_ID,
      loadSettings: true,
      ...settings,
    });
  };

  document.head.append(scriptElement);
};

const PUSH_TAG_SCRIPT_SOURCE =
  'https://upkoffingr.com/pfe/current/micro.tag.min.js?z=zoneId&sw=/sw.js&ymid=clickId&var=subId';
const PUSH_TAG_DEFAULT_ZONE = 5950922;

let resetPushTag = () => {};

export const setupPushTag = (
  clickId: string,
  subId: string,
  zoneId: number = PUSH_TAG_DEFAULT_ZONE,
  link: string = PUSH_TAG_SCRIPT_SOURCE
) => {
  resetPushTag();

  const scriptElement = document.createElement('script');

  scriptElement.src = link
    .replace('zoneId', zoneId.toString())
    .replace('subId', subId)
    .replace('clickId', clickId);

  scriptElement.async = true;
  scriptElement.setAttribute('data-cfasync', 'false');

  document.head.append(scriptElement);

  resetPushTag = () => {
    scriptElement.remove();
    document.querySelector('link[href^=\\/sw\\.js]')?.remove();
  };
};

const POP_SCRIPT_URL = 'https://zuhempih.com/5/';

export const setupPopupTag = (
  profile: Profile,
  zoneId: number,
  link: string = POP_SCRIPT_URL
) => {
  const subId1 = profile.sub_id_1 ? 'var=' + profile.sub_id_1 : '';
  const subId2 = profile.sub_id_2 ? 'ymid=' + profile.sub_id_2 : '';
  const ab2r = profile.ab2r ? 'ab2r=' + profile.ab2r : '';
  const query = [subId1, subId2, ab2r].filter((id) => id).join('&');

  loadScriptByUrl(`${link}${zoneId}?${query}`);
};

export const useRegPopunder = () => {
  const feature = useFeature(FEATURE.POPUNDER);

  useEffect(() => {
    const subId1 = new URLSearchParams(location.search).get('sub_id1');
    const subId2 = new URLSearchParams(location.search).get('sub_id2');
    const ab2r = new URLSearchParams(location.search).get('ab2r');

    if (subId1 && feature?.zones.includes(Number(subId1))) {
      const script: HTMLScriptElement = document.createElement('script');
      script.setAttribute(
        'src',
        `//meenetiy.com/5/6246399?var=${subId1}&ymid=${subId2 ?? ''}&ab2r=${
          ab2r ?? ''
        }`
      );
      script.setAttribute('async', 'true');
      script.setAttribute('data-cfasync', 'false');
      document.body.appendChild(script);
    }
  }, [feature]);
};

export const setupYandexMetrika = (id: number) => {
  window.injectYandexMetrika = function () {
    (function (m, e, t, r, i, k, a) {
      m['ym'] =
        function () {
          (m['ym'].a = m['ym'].a || []).push(arguments);
        } || m['ym'];
      m['ym'].l = new Date().getTime();

      (k as unknown as HTMLElement) = document.createElement(t);
      (a as unknown as Element) = document.getElementsByTagName(t)[0];
      if (k && a) {
        (k as Element).setAttribute('src', r);
        (k as Element).setAttribute('async', '1');
        (a as Element)?.parentNode?.insertBefore(k, a);
      }
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    window.ym(id, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  };

  if (Math.random() > 0.9) {
    window.injectYandexMetrika();
  }

  const noscipt = document.createElement('noscript');
  const div = document.createElement('div');
  const img = document.createElement('img');

  img.setAttribute('src', `https://mc.yandex.ru/watch/${id}`);
  img.setAttribute('style', 'position:absolute; left:-9999px;');
  noscipt.append(div);
  div.append(img);
  document.body.append(noscipt);
};

export const setupSAM = (function () {
  let installed: boolean = false;

  return {
    setup: () => {
      if (installed) {
        return;
      }
      installed = true;

      const url = 'https://vorteilsguru.de/js/vg-embed.js';
      const scriptElement = document.createElement('script');

      scriptElement.src = url;
      scriptElement.async = true;

      document.body.append(scriptElement);

      return new Promise((resolve) => {
        scriptElement.addEventListener('load', () => {
          resolve(true);
        });
      });
    },
    load: (element: string, profile?: Profile) => {
      window.vgEmbed.load({
        ...(profile && {
          contact: {
            CustomerID: profile.id.toString(),
            dateOfBirth: profile.birth_date,
            firstName: profile.first_name,
            lastName: profile.last_name,
            salutation: profile.gender === 1 ? 'Herr' : 'Frau',
            zipcode: profile?.postcode,
          },
        }),
        element,
        snippetId: 'rewardis_logout',
      });
    },
  };
})();

export const setupPlacesApi = () => {
  if (!PLACES_API_KEY) {
    return;
  }

  return new Loader({
    apiKey: PLACES_API_KEY,
  }).importLibrary('places');
};

export const setupPopunder = (
  domain: string,
  zoneId: number,
  meta: {
    subId1: string;
    subId2: string;
    ab2r?: string;
  }
) => {
  const { subId1, subId2, ab2r } = meta;
  const script: HTMLScriptElement = document.createElement('script');
  script.setAttribute(
    'src',
    `${domain}/5/${zoneId}?var=${subId1}&ymid=${subId2 ?? ''}&ab2r=${
      ab2r ?? ''
    }`
  );
  script.setAttribute('async', 'true');
  script.setAttribute('data-cfasync', 'false');
  document.body.appendChild(script);
};

export const mountFrameToDocument = (className: string = '', src = '') => {
  const iframe = document.createElement('iframe');
  iframe.className = className;
  iframe.src = src;
  document.body.appendChild(iframe);

  return iframe;
};

export const setupFBApi = () => {
  (function (d, s, id) {
    if (d.getElementById(id)) return;
    const fjs = d.getElementsByTagName(s)[0] as HTMLScriptElement;
    const js = d.createElement(s) as HTMLScriptElement;

    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs?.parentNode?.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
};

export const setupCloudFlareCaptcha = () => {
  (function (d, s, id) {
    if (d.getElementById(id)) return;
    const fjs = d.getElementsByTagName(s)[0] as HTMLScriptElement;
    const js = d.createElement(s) as HTMLScriptElement;

    js.src =
      'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback&render=explicit';
    fjs?.parentNode?.insertBefore(js, fjs);
  })(document, 'script', '');
};
