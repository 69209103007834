import { makeStyles } from '@griffel/react';

import BalanceIcon from 'src/assets/ticket-green.svg';

export const useSlotStyles = makeStyles({
  root: {
    width: '100px',
    height: '100px',
    display: 'flex',
    transition: 'all 0.3s linear',
    background: '#FFFFFF',
    borderRadius: '16px',
  },
  img: {
    margin: 'auto',
    width: '93%',
    height: '93%',
  },
});

export const useSlotMachineStyles = makeStyles({
  container: {
    maxWidth: '360px',
    margin: '16px auto',
  },
});

export const useSlotContainerStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    justifyContent: 'center',
  },
});

export const useSlotButtonsStyles = makeStyles({
  container: {
    position: 'relative',
    margin: '0 16px',
    paddingTop: '16px',
  },
  betTitle: {
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '16px',
    color: '#FFFFFF',
  },
  betContainer: {
    position: 'relative',
    height: '40px',
  },
  betValue: {
    top: 0,
    width: '40px',
    height: '40px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    userSelect: 'none',
    fontWeight: '600',
    fontSize: '16px',
  },
  control: {
    display: 'flex',
    width: '150px',
    justifyContent: 'space-between',
    margin: '8px auto 16px',
    alignItems: 'center',
  },
  controlButton: {
    border: '2px solid #11B479',
    background: 'transparent',
    width: '32px',
    height: '32px',
    color: '#11B479',
    padding: 0,
    display: 'flex',
    transition: 'all 0.2s linear',
    '& svg': {
      margin: 'auto',
      fill: 'black',
    },
    '&:hover': {
      'border-color': '#11B479',
      'box-shadow': 'inset 0px 0px 2px 0px rgba(0,0,0,0.65); ',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
});

export const useSlotBalanceStyles = makeStyles({
  root: {
    position: 'absolute',
    right: '0',
    display: 'flex',
    color: '#FFFFFF',
    fontSize: '12px',
    gap: '8px',
    alignItems: 'center',
  },
  balance: {
    textAlign: 'center',
    userSelect: 'none',
    color: '#FFFFFF',
    fontWeight: 500,
    background: `no-repeat url(${BalanceIcon}) 50% 50%/contain`,
  },
  balanceSmall: {
    width: '28px',
    height: '28px',
    lineHeight: '28px',
  },
  balanceLarge: {
    width: '48px',
    height: '48px',
    fontSize: '20px',
    lineHeight: '48px',
  },
});

export const useSlotWinModalStyles = makeStyles({
  title: {
    fontSize: '20px',
    textAlign: 'center',
  },
  prize: {
    margin: '8px 0',
    display: 'flex',
    justifyContent: 'center',
  },
});
