import Button from '@mui/material/Button';
import balanceImage from 'src/assets/balance-white.svg';
import { useIntl } from 'react-intl';
import {
  SAVE_CLICK_ELEMENT_NAME,
  SMARTLOOK_CUSTOM_EVENT,
} from 'src/constants/enums';
import { smartlookTrackEvent } from 'src/services/GoogleTagManagerService';
import { ROUTE_TASKS } from 'src/constants/routes';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import useLastClick from 'src/hooks/useLastClick';
import useEarnTicketRedirect from 'src/hooks/useEarnTicketRedirect';

type Props = {
  container: string;
  onScroll?: () => void;
};

const EarnTicketButton: FunctionComponent<Props> = ({
  container,
  onScroll,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { saveLast } = useLastClick();
  const [isFeatureEnabled, redirect] = useEarnTicketRedirect();

  const handleEarnButtonClick = () => {
    saveLast(`${container}_${SAVE_CLICK_ELEMENT_NAME.EARN_TICKET}`);
    smartlookTrackEvent(SMARTLOOK_CUSTOM_EVENT.EARN_TICKET_CLICK);

    if (isFeatureEnabled) {
      redirect();

      return;
    }

    const containerElement = document.getElementById('tasks');
    if (containerElement) {
      if (onScroll) {
        onScroll();
      }
      setTimeout(() => {
        containerElement.scrollIntoView({ behavior: 'smooth' });
      }, 10);
    } else {
      navigate(ROUTE_TASKS);
    }
  };

  return (
    <Button
      fullWidth
      size="large"
      variant="contained"
      onClick={handleEarnButtonClick}
      sx={{ background: '#3C25D0' }}
    >
      {intl.formatMessage({
        id: 'form.earn_tickets',
        defaultMessage: 'Earn tickets',
      })}
      <img src={balanceImage} style={{ marginLeft: '0.5em' }} />
    </Button>
  );
};

export default EarnTicketButton;
