import Button from '@mui/material/Button';

import { ReactComponent as GoogleIcon } from 'src/assets/icons/google.svg';
import { useIntl } from 'react-intl';
import { FunctionComponent } from 'react';

const GoogleButtonStandard: FunctionComponent<{
  onClick: () => void;
  isMobile?: boolean;
}> = ({ onClick, isMobile = false }) => {
  const intl = useIntl();

  return (
    <Button
      sx={(theme) => ({
        flex: 1,
        width: '100%',
        ...(isMobile
          ? {
              [theme.breakpoints.up('sm')]: {
                display: 'none',
              },
            }
          : {
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }),
      })}
      color="inherit"
      variant="contained"
      startIcon={<GoogleIcon />}
      onClick={onClick}
      id="signup_google"
    >
      {intl.formatMessage(
        {
          id: 'sign_in_with',
          defaultMessage: 'Sign in with Google',
        },
        {
          name: 'Google',
        }
      )}
    </Button>
  );
};

export default GoogleButtonStandard;
