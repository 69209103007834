import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material';
import { checkboxClasses } from '@mui/material/Checkbox';
import { inputClasses } from '@mui/material/Input';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { formLabelClasses } from '@mui/material/FormLabel';
import { autocompleteClasses } from '@mui/material/Autocomplete';

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    h2: {
      fontSize: '45px',
      fontWeight: 500,
      lineHeight: '38px',
    },
    h3: {
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '38px',
    },
    h4: {
      fontSize: '22px',
      fontWeight: 500,
      lineHeight: '48px',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: '0',
          borderRadius: '12px',
          background: '#EFEFF5',
          paddingRight: 0,
          height: theme?.custom?.control?.height ?? 42,
          fontSize: theme?.custom?.control?.fontSize ?? '16px',
          fontWeight: theme?.custom?.control?.fontWeight ?? 400,
          [`&.${inputClasses.error}`]: {
            background: theme.palette.error.light,
          },
          overflow: 'hidden',
        }),
        input: {
          height: '0.95em',
          borderRadius: '12px',
          paddingRight: 32,
          '&:-webkit-autofill': {
            background: 'black',
          },
        },
        notchedOutline: {
          borderColor: 'rgba(0,0,0,0)',
          borderWidth: 2,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& svg': {
            width: 16,
            height: 16,
          },
          position: 'absolute',
          right: 16,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          [`&:not(.${checkboxClasses.checked}) .MuiBox-root`]: {
            border: `2px solid ${
              ownerState.color === 'error'
                ? theme.palette.error.main
                : theme.palette.text.primary
            }`,
            backgroundColor:
              ownerState.color === 'error' ? theme.palette.error.light : '',
          },
          padding: 0,
          margin: 0,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: 12,
          fontSize: '15px',
          textTransform: 'none',
          height: theme?.custom?.control?.height ?? 42,
          lineHeight: 1,
          ':focus': {
            outline: 'none',
          },
          ...(ownerState.color === 'secondary' &&
            (ownerState.variant !== 'contained'
              ? {
                  color: '#07091D',
                  backgroundColor: '#EFEFF5',
                  padding: 16,
                  ':hover': {
                    backgroundColor: '#e9e9f3',
                    boxShadow: 'none',
                  },
                  ':focus': {
                    outline: 'none',
                  },
                }
              : {
                  '&(not:disabled):hover': {
                    boxShadow: 'inset 0 0 10px 1000px rgb(42,44,57)',
                  },
                })),
          ...(ownerState.variant !== 'contained' &&
            ownerState.color === 'success' && {
              color: theme.palette.success.light,
              padding: 16,
              backgroundColor: '#EBF8EE',
              ':hover': {
                boxShadow: 'none',
                backgroundColor: '#EBF8EE',
              },
              ':focus': {
                outline: 'none',
              },
              path: {
                fill: theme.palette.success.light,
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'inherit' && {
              color: '#FFFFFF',
              padding: 16,
              backgroundColor: '#2A2C39',
              ':hover': {
                backgroundColor: 'rgba(42, 44, 57, 0.8)',
                color: '#FFFFFF',
              },
              ':focus': {
                outline: 'none',
              },
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant !== 'contained' && {
              padding: 0,
              textDecoration: 'underline',
              height: 'auto',
              color: theme.palette.primary.main,
              ':focus': {
                outline: 'none',
              },
              ':hover': {
                color: '#747bff',
              },
            }),
          ...(ownerState.size === 'small' && {
            padding: '8px 16px',
            height: 32,
            borderRadius: 8,
          }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          if (ownerState.type === 'button') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              position: 'relative',
              backgroundColor: '#EFEFF5',
              outline: 0,
              border: 0,
              margin: 0,
              padding: '16px',
              borderRadius: '12px',
              cursor: 'pointer',
              userSelect: 'none',
              verticalAlign: 'middle',
              textDecoration: 'none',
              color: '#07091D',
              minWidth: '64px',
              fontWeight: 500,
              transition:
                'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              '&:hover': {
                color: '#07091D',
                backgroundColor: '#e9e9f3',
              },
              ...(ownerState['aria-disabled'] === true && {
                pointerEvents: 'none',
              }),
            };
          }
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {},
    },
    MuiModal: {
      styleOverrides: {
        backdrop: ({ ownerState }) => ({
          position: 'absolute',
          height: '100%',
          opacity: '0 !important',
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          paddingRight: '14px !important',
        },
        icon: {
          display: 'none',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: () => ({
          '& legend': {
            display: 'none',
          },
          '& fieldset': {
            top: 0,
          },
          [`& .${outlinedInputClasses.root} .${autocompleteClasses.input}`]: {
            padding: '5px 7px',
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: () => ({
          transform: 'translate(14px, 10px)',
          [`&.${formLabelClasses.filled}`]: {
            transform: 'scale(0)',
          },
        }),
      },
    },
  },
  palette: {
    primary: {
      main: '#3D25D2',
      light: '#FFFFFF',
    },
    secondary: {
      main: '#07091D',
      light: '#131526',
    },
    error: {
      main: '#FF8787',
      light: '#FDF2F2',
    },
    success: {
      main: '#03A369',
      light: '#11B479',
    },
    text: {
      primary: '#07091D',
      disabled: '#6D6E81',
      secondary: '#FFFFFF',
    },
    background: {
      default: '#07091D',
      paper: '#FFFFFF',
    },
  },
  custom: {
    footer: {
      background: '#07091D',
      color: '#FFF',
    },
    sweepstakes: {
      backgrounds: ['#F0F7FE', '#F1F9F3', '#EFF7FE', '#F6F8FA'],
    },
    control: {
      height: '42px',
      fontWeight: 400,
      fontSize: '1em',
    },
    genderField: {
      height: '42px',
    },
    earningStreamItem: {
      mainBackground: '#1D1F34',
      secondBackground: '#131526',
      mainColor: '#FFFFFF',
      secondColor: '#686A77',
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
