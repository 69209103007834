import { FunctionComponent } from 'react';
import { mergeClasses } from '@griffel/react';

import { useSlotBalanceStyles } from './get-styles';
import { useIntl } from 'react-intl';

type Props = {
  value: number;
};

const Balance: FunctionComponent<Props> = ({ value }) => {
  const styles = useSlotBalanceStyles();
  const balanceStyles = mergeClasses(styles.balance, styles.balanceSmall);
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <div>
        {intl.formatMessage({
          id: 'balance',
          defaultMessage: 'Balance',
        })}
      </div>
      <div className={balanceStyles}>{value}</div>
    </div>
  );
};

export default Balance;
