import { FunctionComponent } from 'react';

import { useArticleListItemStyles } from './get-styles';

type Props = {
  data: {
    id: number;
    title: string;
    preview: string;
  };
};

const ArticlesListItem: FunctionComponent<Props> = ({ data }) => {
  const classes = useArticleListItemStyles();
  const redirect = () => {
    window.open(`/article/${data.id}`, '_blank');
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.img}
        style={{ backgroundImage: `url(/articles/${data.id}-1.jpg)` }}
      />
      <div className={classes.desc}>
        <div className={classes.title} onClick={redirect}>
          {data.title}
        </div>
        <div className={classes.preview}>{data.preview}</div>
        <div className={classes.readMore} onClick={redirect}>
          Read more
        </div>
      </div>
    </div>
  );
};

export default ArticlesListItem;
