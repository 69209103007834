import { INeedBrowsersForIntent, TYPE_BROWSERS_WV } from './types';
import { BROWSERS_FULL } from 'src/components/InAppOverlay/browsersFull';
import { BROWSER } from 'src/components/InAppOverlay/browsers';

type listOfWebviewBrowsers = {
  [key in TYPE_BROWSERS_WV]?: RegExp[];
};

class InApp {
  ua: string;
  useBrowsersForIntent?: INeedBrowsersForIntent;

  private currentBrowser: string | undefined;
  private listOfWebviewBrowsers: listOfWebviewBrowsers | undefined;

  constructor(
    useragent: string,
    useBrowsersForIntent?: INeedBrowsersForIntent
  ) {
    this.ua = useragent;
    this.useBrowsersForIntent = useBrowsersForIntent;

    this.listOfWebviewBrowsers = Object.keys(BROWSERS_FULL)
      .filter((key) => key.startsWith('wv_'))
      .reduce((obj, key) => {
        return { ...obj, [key]: BROWSERS_FULL[key] };
      }, {} as listOfWebviewBrowsers);
  }

  get fullBrowser(): string {
    if (this.currentBrowser) return this.currentBrowser;

    let browserKey;
    browserKey = Object.entries(BROWSERS_FULL).find(([, regexArray]) =>
      regexArray.some((regex) => regex.test(this.ua))
    );

    this.currentBrowser = browserKey ? browserKey[0] : 'other';
    return this.currentBrowser;
  }

  get browser(): string {
    let browserKey;

    browserKey = Object.entries(BROWSER).find(([, regex]) =>
        regex.test(this.ua)
    );
    return browserKey ? browserKey[0] : 'other';
  }

  get isInApp(): boolean {
    if (['mobile_safari', 'safari'].includes(this.fullBrowser)) {
      if (window.TelegramWebviewProxy && window.TelegramWebviewProxyProto) {
        return true;
      }
    }

    if (['chrome_mobile', 'chrome', 'chrome_chromium'].includes(this.fullBrowser)) {
      if (!window.chrome) {
        return true;
      }
    }

    return this.checkIsInAppByUserAgent();
  }

  checkIsInAppByUserAgent() {
    const baseRules = [
      'WebView',
      // '(iPhone|iPod|iPad)(?!.*(Chrome|CriOS|CrMo|Edge|OPR|Edg|EdgiOS))(.*Safari/)',
      'Android.*(wv)',
    ];

    const customRules = this.useBrowsersForIntent
      ? Object.values(this.useBrowsersForIntent)
          .filter((value) => value.isEnabled)
          .map((value) => value.type)
      : [];

    if (this.listOfWebviewBrowsers?.[this.fullBrowser as TYPE_BROWSERS_WV]) {
      return true;
    }

    const allRules = [...baseRules, ...customRules];
    const regex = new RegExp(`(${allRules.join('|')})`, 'i');

    return regex.test(this.ua);
  }
}

export default InApp;
