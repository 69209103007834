import React, { FC } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import AuthRequired from 'src/components/AuthRequired';
import AuthNoRequired from 'src/components/AuthNoRequired';
import Logout from 'src/components/Logout';
import * as ROUTES from 'src/constants/routes';
import lazy from 'src/lib/lazyImport';

import LayoutSignup from 'src/layouts/LayoutSignup';
import RegistrationPage from 'src/pages/RegistrationPage';
import LayoutPreSignup from 'src/layouts/presignup/LayoutPreSignup';

const FortunePage = lazy(() => import('src/pages/games/FortunePage'));
const CookiePolicyPage = lazy(
  () => import('src/pages/documents/CookiePolicyPage')
);
const HomePage = lazy(() => import('src/pages/HomePage'));
const TasksPage = lazy(() => import('src/pages/TasksPage'));
const NoMatchPage = lazy(() => import('src/pages/NoMatchPage'));
const LoginPage = lazy(() => import('src/pages/LoginPage'));
const OptOutPage = lazy(() => import('src/pages/OptOutPage'));
const TermsOfUsePage = lazy(() => import('src/pages/documents/TermsPage'));
const ProfileRootPage = lazy(() => import('src/pages/account/ProfilePage'));
const ProfileMyInfoPage = lazy(() => import('src/pages/account/MyInfoPage'));
const ProfileSecurityPage = lazy(
  () => import('src/pages/account/SecurityPage')
);
const PrivacyPage = lazy(() => import('src/pages/documents/PrivacyPage'));
const FaqPage = lazy(() => import('src/pages/Faq'));
const PrizeDrawingsPage = lazy(() => import('src/pages/PrizeDrawings'));
const PartnersProgramPage = lazy(() => import('../../../src/pages/referrals'));
const RefCheckerPage = lazy(() => import('src/pages/RefCheckerPage'));
const ResetPasswordPage = lazy(() => import('src/pages/ResetPasswordPage'));
const CreateNewPasswordPage = lazy(
  () => import('src/pages/create-new-password')
);
const SurveyDetailPage = lazy(() => import('src/pages/SurveyDetailPage'));
const AuthRedirectPage = lazy(() => import('src/pages/redirects/AuthPage'));
const ConfirmationRedirectPage = lazy(
  () => import('src/pages/redirects/ConfirmationPage')
);
const SmartLinkPage = lazy(() => import('src/pages/SmartlinkPage'));
const WinnersPage = lazy(() => import('src/pages/WinnersPage'));
const RatingPage = lazy(() => import('src/pages/RatingPage'));
const LayoutMain: FC<NewLayoutMainProps> = lazy(
  () => import('src/layouts/NewLayoutMain')
);
const LayoutBase = lazy(() => import('src/layouts/LayoutBase'));
const LayoutGames = lazy(() => import('src/layouts/LayoutGames'));

import {
  ROUTE_REFERRALS,
  ROUTE_REF_CHECKER,
  ROUTE_SMARTLINK,
  ROUTE_GAMES,
} from 'src/constants/routes';
import AppDynamic from './AppDynamic';
import Loader from 'src/components/Loader';
import LayoutPushReg from 'src/layouts/LayoutPushReg';
import ErrorBoundaryWrapper from 'src/components/ErrorBoundary';
import ArticlePage from 'src/pages/articles/ArticlePage';
import LayoutLanding from 'src/layouts/LayoutLanding';
import { NewLayoutMainProps } from 'src/layouts/NewLayoutMain';
import SlotMachinePage from 'src/pages/games/SlotMachinePage';
import GamesPage from 'src/pages/games/GamesPage';

const {
  ROUTE_HOMEPAGE,
  ROUTE_AUTH,
  ROUTE_FORTUNE,
  ROUTE_TASKS,
  ROUTE_RATING,
  ROUTE_PRIZE_DRAWING,
  ROUTE_CONFIRMATION,
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_LOGOUT,
  ROUTE_ACCOUNT_PROFILE,
  ROUTE_ACCOUNT_SECURITY,
  ROUTE_PAGE_COOKIE,
  ROUTE_PAGE_PRIVACY,
  ROUTE_PAGE_FAQ,
  ROUTE_PAGE_TERMS,
  ROUTE_PAGE_OPT_OUT,
  ROUTE_SIGNIN,
  ROUTE_RESET_PASSWORD,
  ROUTE_CREATE_NEW_PASSWORD,
  ROUTE_SIGNUP,
  ROUTE_ARTICLE_DETAIL,
  ROUTE_WINNERS,
  ROUTE_SURVEY_DETAIL,
  ROUTE_SLOT_MACHINE,
} = ROUTES;

const mainPageRouter = {
  element: <LayoutMain withoutFlex />,
  path: ROUTE_HOMEPAGE,
  children: [
    {
      path: '',
      element: <HomePage />,
    },
  ],
};

const optOutRouter = {
  element: <LayoutBase />,
  path: ROUTE_PAGE_OPT_OUT,
  children: [
    {
      element: <OptOutPage />,
      path: '',
    },
  ],
};

const authRouter = {
  element: <LayoutBase />,
  path: '',
  children: [
    {
      element: <AuthNoRequired />,
      path: '',
      children: [
        {
          element: <LoginPage />,
          path: ROUTE_SIGNIN,
        },
        {
          element: <ResetPasswordPage />,
          path: ROUTE_RESET_PASSWORD,
        },
        {
          element: <AuthRedirectPage />,
          path: ROUTE_AUTH,
        },
      ],
    },
  ],
};

const smartlinkPageRouter = {
  element: <AuthNoRequired />,
  path: ROUTE_SMARTLINK,
  children: [
    {
      element: <SmartLinkPage />,
      path: '',
    },
  ],
};

const mainAuthedRouter = {
  element: <AuthRequired />,
  path: '',
  children: [
    {
      element: <LayoutMain />,
      path: '',
      children: [
        {
          element: <PartnersProgramPage />,
          path: ROUTE_REFERRALS,
        },
        {
          element: <CreateNewPasswordPage />,
          path: ROUTE_CREATE_NEW_PASSWORD,
        },
        {
          element: <TasksPage />,
          path: `${ROUTE_TASKS}/:id`,
        },
        {
          element: <TasksPage />,
          path: ROUTE_TASKS,
        },
        {
          element: <RatingPage />,
          path: ROUTE_RATING,
        },
        {
          element: <ProfileRootPage />,
          path: ROUTE_ACCOUNT,
          children: [
            {
              path: ROUTE_ACCOUNT_PROFILE,
              element: <ProfileMyInfoPage />,
            },
            {
              path: ROUTE_ACCOUNT_SECURITY,
              element: <ProfileSecurityPage />,
            },
            {
              path: '*',
              element: <ProfileMyInfoPage />,
            },
          ],
        },
        {
          element: <Logout />,
          path: ROUTE_ACCOUNT_LOGOUT,
        },
        {
          element: <WinnersPage />,
          path: ROUTE_WINNERS,
        },
      ],
    },
    {
      element: <LayoutGames />,
      path: ROUTE_GAMES,
      children: [
        {
          element: <GamesPage />,
          path: '',
        },
        {
          element: <SlotMachinePage />,
          path: ROUTE_SLOT_MACHINE,
        },
        {
          element: <FortunePage />,
          path: ROUTE_FORTUNE,
        },
      ],
    },
    {
      element: <LayoutBase />,
      path: ROUTE_SURVEY_DETAIL,
      children: [
        {
          element: <SurveyDetailPage />,
          path: '',
        },
      ],
    },
    {
      element: <LayoutGames />,
      path: ROUTE_FORTUNE,
      children: [{ element: <FortunePage />, path: '' }],
    },
    {
      element: <LayoutBase />,
      path: ROUTE_CONFIRMATION,
      children: [
        {
          element: <ConfirmationRedirectPage />,
          path: '',
        },
      ],
    },
  ],
};

const mainNoAuthedRouter = {
  element: <LayoutMain />,
  path: '',
  children: [
    {
      element: <RefCheckerPage />,
      path: ROUTE_REF_CHECKER,
    },
    {
      element: <PrizeDrawingsPage />,
      path: ROUTE_PRIZE_DRAWING,
    },
    {
      element: <FaqPage />,
      path: ROUTE_PAGE_FAQ,
    },
    {
      element: <TermsOfUsePage />,
      path: ROUTE_PAGE_TERMS,
    },
    {
      element: <PrivacyPage />,
      path: ROUTE_PAGE_PRIVACY,
    },
    {
      element: <CookiePolicyPage />,
      path: ROUTE_PAGE_COOKIE,
    },
  ],
};

const signUpRouter = {
  element: <LayoutPreSignup />,
  path: ROUTE_SIGNUP,
  children: [
    {
      element: <LayoutSignup />,
      path: '',
      children: [
        {
          element: <LayoutPushReg />,
          path: '',
          children: [
            {
              element: <RegistrationPage />,
              path: '',
            },
          ],
        },
      ],
    },
  ],
};

const articlesRouter = {
  element: (
    <LayoutLanding>
      <ArticlePage />
    </LayoutLanding>
  ),
  path: ROUTE_ARTICLE_DETAIL,
};

const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundaryWrapper>
        <AppDynamic />
      </ErrorBoundaryWrapper>
    ),
    path: '/',
    loader: () => <Loader />,
    children: [
      smartlinkPageRouter,
      mainPageRouter,
      mainAuthedRouter,
      mainNoAuthedRouter,
      optOutRouter,
      authRouter,
      signUpRouter,
      articlesRouter,
      {
        element: <NoMatchPage />,
        path: '*',
      },
    ],
  },
]);

export default router;
