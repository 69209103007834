import { FunctionComponent, memo } from 'react';
import { ValidationSchemaProps } from 'src/components/forms/RegistationForm/typings';
import * as yup from 'yup';
import { useField } from 'formik';
import { useCloudflareCaptchaLoader } from 'src/providers/CloudflareCaptchaProvider/CloudflareCaptchaProvider';
import Box from '@mui/material/Box';
import { useCloudflareWidget } from 'src/providers/CloudflareCaptchaProvider/useCloudflareCaptchaWidget';

const CONTAINER_ID = 'cloudflare-c';

export const initialValue = '';

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup.string().required(
    intl.formatMessage({
      id: 'form.validation.required.cloudflare',
      defaultMessage: 'This field is required',
    })
  );

type Props = {
  name: string;
  callback: () => void;
};

export const Component: FunctionComponent<Props> = memo((props) => {
  const [, , helpers] = useField(props);
  const { setValue } = helpers;

  useCloudflareCaptchaLoader();

  useCloudflareWidget(`#${CONTAINER_ID}`, (token) => {
    props?.callback();
    setValue(token);
  });

  return (
    <Box
      id={CONTAINER_ID}
      sx={{
        marginY: 2,
        width: '100%',
      }}
    />
  );
});
