import { useRecoilState } from 'recoil';
import { slotMachinePartialSelector } from 'src/recoil/slotMachine/slotMachine';
import {
  getSlotMachineSymbols,
  spinSlotMachine,
} from 'src/services/GameService';
import {
  machineProcessSelector,
  SLOT_MACHINE_PROCESS,
} from 'src/recoil/slotMachine/slotMachineProcess';
import useRequest from 'src/hooks/useRequest';
import { useEffect, useRef } from 'react';
import { isSuccess } from 'src/lib/remoteData';
import {
  useProfileDispatch,
  useProfileState,
} from 'src/providers/ProfileProvider';

const PLAYING_PROCESS = [
  SLOT_MACHINE_PROCESS.PLAYING,
  SLOT_MACHINE_PROCESS.FINISHING,
  SLOT_MACHINE_PROCESS.CHECKING,
];

const UPDATE_BALANCE_TIMEOUT = 1500;

const useSlotMachine = () => {
  const [state, set] = useRecoilState(slotMachinePartialSelector);
  const [process, setProcess] = useRecoilState(machineProcessSelector);
  const [symbolsRequest] = useRequest(getSlotMachineSymbols);
  const { setProfileField } = useProfileDispatch();
  const { profile } = useProfileState();
  const updateBalanceTimeoutId = useRef<NodeJS.Timeout>();

  const play = async (bet: number) => {
    setProcess(SLOT_MACHINE_PROCESS.PLAYING);
    setProfileField('balance', (profile?.balance as number) - bet);

    const response = await spinSlotMachine(bet);
    if (isSuccess(response)) {
      set({
        winMatrix: response.data.win_matrix,
        symbolsMatrix: response.data.matrix,
        win: response.data.win,
        balance: response.data.balance,
      });

      updateBalanceTimeoutId.current = setTimeout(() => {
        if (response.data.win) {
          setProfileField('balance', response.data.balance);
        } else {
          cleanProcess();
        }
      }, UPDATE_BALANCE_TIMEOUT);
    }
    setProcess(SLOT_MACHINE_PROCESS.FINISHING);
  };

  const cleanProcess = () => {
    setProcess(SLOT_MACHINE_PROCESS.EMPTY);
  };

  useEffect(() => {
    return () => {
      cleanProcess();
      clearTimeout(updateBalanceTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (isSuccess(symbolsRequest)) {
      set({
        symbols: symbolsRequest.data,
      });
    }
  }, [symbolsRequest]);

  return {
    play,
    isLoaded: !!state.symbols?.length,
    isPlaying: PLAYING_PROCESS.includes(process),
    win: process === SLOT_MACHINE_PROCESS.CHECKING ? state.win : 0,
    cleanProcess,
  };
};

export default useSlotMachine;
