import { useFeature } from 'src/providers/FeaturesProvider';
import { useProfileState } from 'src/providers/ProfileProvider';
import { FEATURE } from 'src/constants/enums';

const useEarnTicketRedirect = (): [
  isFeatureEnabled: boolean,
  redirect: () => void,
] => {
  const earnTicketRedirect = useFeature(FEATURE.EARN_TICKET_REDIRECT);
  const { profile } = useProfileState();

  const redirect = () => {
    if (!earnTicketRedirect) {
      return;
    }

    const redirectUrl = earnTicketRedirect.offer_id
      ? `/api/v1/offers_click/?offer_id=${earnTicketRedirect.offer_id}&user_id=${profile?.id}`
      : earnTicketRedirect?.redirect_url || '/';
    window.open(redirectUrl, '_blank');
  };

  return [!!earnTicketRedirect, redirect];
};

export default useEarnTicketRedirect;
