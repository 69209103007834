import React, { memo } from 'react';
import Slot from './Slot';
import randomInt from 'src/utils/math';

import { useSlotContainerStyles } from './get-styles';

const row = new Array(9).fill(0);

const SlotContainer = () => {
  const styles = useSlotContainerStyles();

  return (
    <div className={styles.root}>
      {row.map((_, index) => (
        <Slot
          timeInterval={randomInt(135, 155)}
          index={[Math.floor(index / 3), index % 3]}
        />
      ))}
    </div>
  );
};

export default memo(SlotContainer);
