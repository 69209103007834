import { FunctionComponent, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import { landingTheme } from 'src/themes';
import CookieConsent from 'src/components/CookieConsent';
import useRequest from 'src/hooks/useRequest';
import * as OffersService from 'src/services/OffersService';
import { isLoading, isNotAsked, isSuccess } from 'src/lib/remoteData';
import Loader from 'src/components/Loader';
import OFFER_MAP from 'src/layouts/landingImagesOld';
import WithThemeColumn from 'src/layouts/WithThemeColumn';
import DebugPanel from 'src/components/DebugPanel';

import logoImage from 'src/assets/logo-white.svg';
import productBackgroundImage from 'src/layouts/assets/theme-column-other.png';

interface LayoutThemeContentProps {
  image?: string;
}

function LayoutThemeContent({
  image,
  children,
}: LayoutThemeContentProps & PropsWithChildren) {
  const intl = useIntl();

  return (
    <WithThemeColumn image={image || productBackgroundImage}>
      <Container maxWidth="xs" sx={{ marginY: 1 }}>
        <img src={logoImage} width="250" alt="Rewardis" />
        <Typography variant="h4">
          {intl.formatMessage({
            id: 'registration_page.sign_up_and_geta_prize',
            defaultMessage: 'Sign up and get a prize',
          })}
        </Typography>
        {children ? children : <Outlet />}
      </Container>
    </WithThemeColumn>
  );
}

const LayoutOfferTheme: FunctionComponent<{ offerId: number }> = ({
  offerId,
}) => {
  const [offerRequest] = useRequest(
    async () => await OffersService.getOffer(offerId)
  );

  if (isNotAsked(offerRequest)) {
    return null;
  }

  if (isLoading(offerRequest)) {
    return (
      <LayoutThemeBase>
        <Loader fullSize />
      </LayoutThemeBase>
    );
  }

  if (isSuccess(offerRequest) && offerRequest.data) {
    return (
      <LayoutThemeBase>
        <LayoutThemeContent image={OFFER_MAP[offerId]} />
      </LayoutThemeBase>
    );
  }

  return (
    <LayoutThemeBase>
      <LayoutThemeContent />
    </LayoutThemeBase>
  );
};

export function LayoutThemeBase({ children }: PropsWithChildren) {
  return (
    <ThemeProvider theme={landingTheme}>
      <CssBaseline />
      <DebugPanel />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '100vh',
          background: '#07091D',
        }}
      >
        {children}
      </Box>
      <CookieConsent />
    </ThemeProvider>
  );
}

const LayoutDesignThemeOld: FunctionComponent<{ offerId?: number }> = ({
  offerId,
}) => {
  if (offerId) {
    return <LayoutOfferTheme offerId={offerId} />;
  }

  return (
    <LayoutThemeBase>
      <LayoutThemeContent />
    </LayoutThemeBase>
  );
};

export default LayoutDesignThemeOld;
