import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import {
  isFailure,
  isLoading,
  isNotAsked,
  RemoteData,
  RemoteDataSuccess,
} from 'src/lib/remoteData';
import Loader from 'src/components/Loader';
import Box from '@mui/material/Box';
import LoadingHeader from './ScreensAfterReg/LoadingHeader';

type ResponseHandler<T> = FC<{ request: RemoteData<T> }>;

interface ResponseProps<T> {
  request: RemoteData<T>;
  loadingText?: string;
  ErrorView?: ResponseHandler<T>;
  EmptyView?: ResponseHandler<T>;
  NotAskedView?: ResponseHandler<T>;
  loadingFullSize?: boolean;
  children: (data: T) => ReactElement | ReactElement[];
}

export default function Response<T>({
  request,
  loadingText,
  loadingFullSize,
  children,
  ErrorView,
  EmptyView,
  NotAskedView,
}: ResponseProps<T>) {
  const intl = useIntl();

  if (isNotAsked(request)) {
    return NotAskedView ? <NotAskedView request={request} /> : null;
  }

  if (isLoading(request)) {
    return (
      <>
        {loadingFullSize && <LoadingHeader />}
        <Box sx={{ paddingY: 2, margin: 'auto' }}>
          <Loader fullSize={loadingFullSize} title={loadingText} />
        </Box>
      </>
    );
  }

  if (isFailure(request)) {
    return ErrorView ? (
      <ErrorView request={request} />
    ) : (
      <div>
        {intl.formatMessage({
          id: 'something_wrong',
          defaultMessage: 'Something is going wrong',
        })}
      </div>
    );
  }

  if (!request.data) {
    return EmptyView ? <EmptyView request={request} /> : null;
  }

  return children(request.data);
}

interface ResponsesProps {
  requests: Array<RemoteData>;
  loadingText?: string;
  loadingFullSize?: boolean;
  children: (data: any) => ReactElement;
}
export function Responses<T>({
  requests,
  loadingText,
  loadingFullSize,
  children,
}: ResponsesProps) {
  const intl = useIntl();

  if (requests.some((request) => isNotAsked(request))) {
    return null;
  }

  if (requests.some((request) => isLoading(request))) {
    return (
      <Box sx={{ paddingY: 2, margin: 'auto' }}>
        <Loader fullSize={loadingFullSize} title={loadingText} />
      </Box>
    );
  }

  if (requests.some((request) => isFailure(request))) {
    return (
      <div>
        {intl.formatMessage({
          id: 'something_wrong',
          defaultMessage: 'Something is going wrong',
        })}
      </div>
    );
  }

  return children(
    requests.map((request) => (request as RemoteDataSuccess<unknown>)?.data)
  );
}
