import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { FieldHookConfig, useField } from 'formik';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useEffect, useMemo, useState } from 'react';
import { ValidationSchemaProps } from '../typings';
import { Box } from '@mui/material';

export const initialValue = '';

export const createValidationSchema = ({ intl }: ValidationSchemaProps) =>
  yup.object().required(
    intl.formatMessage({
      id: 'form.validation.required',
      defaultMessage: 'This field is required',
    })
  );

type Result =
  | 0 // empty
  | 1 // correct
  | 2; // incorrect

export interface CustomCaptchaValue {
  result: Result;
  solution?: number;
  expression?: string;
}

interface SingProps {
  sign: string;
}

function Sign({ sign }: SingProps) {
  return <Box sx={{ flex: '0 0 10px', lineHeight: 3.5 }}>{sign}</Box>;
}

const createNumber = () => Math.ceil(Math.random() * 10) - 1;
const createOperands = () => [createNumber(), createNumber()];

export function Component(props: FieldHookConfig<CustomCaptchaValue>) {
  const [field, meta, helpers] = useField(props);
  const [firstOperand, secondOperand] = useMemo(() => createOperands(), []);
  const [solution, setSolution] = useState<number>();
  const intl = useIntl();

  const hasError = Boolean(meta.touched && meta.error);
  const expression = `${firstOperand} + ${secondOperand}`;

  useEffect(() => {
    if (solution) {
      const result = firstOperand + secondOperand === solution ? 1 : 2;

      helpers.setTouched(true);
      helpers.setValue({
        result,
        solution,
        expression,
      });
    } else {
      helpers.setValue({
        result: 0,
        expression,
      });
    }
  }, [solution]);

  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        marginY: 1,
      }}
    >
      <FormLabel>
        {intl.formatMessage({
          id: 'form.evaluate_expression',
          defaultMessage: 'Evaluate expression',
        })}
      </FormLabel>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          width: '100%',
        }}
      >
        <FormControl
          sx={{ flex: '0 0 15%' }}
          fullWidth
          variant="outlined"
          error={hasError}
        >
          <OutlinedInput
            fullWidth
            disabled
            value={firstOperand}
            inputProps={{ style: { textAlign: 'center' } }}
          />
        </FormControl>

        <Sign sign="+" />

        <FormControl
          sx={{ flex: '0 0 15%' }}
          fullWidth
          variant="outlined"
          error={hasError}
        >
          <OutlinedInput
            fullWidth
            disabled
            value={secondOperand}
            inputProps={{ style: { textAlign: 'center' } }}
          />
        </FormControl>

        <Sign sign="=" />

        <FormControl
          variant="outlined"
          sx={{ flex: 'auto' }}
          error={Boolean(meta.touched && meta.error)}
        >
          <OutlinedInput
            name={props.name}
            placeholder={intl.formatMessage({
              id: 'form.solution',
              defaultMessage: 'Solution',
            })}
            value={solution}
            inputProps={{
              style: { textAlign: 'center' },
            }}
            onChange={(e) => {
              setSolution(Number(e.target.value));
            }}
          />
        </FormControl>
      </Box>
    </FormGroup>
  );
}
