import { Outlet } from 'react-router-dom';
import WebsocketProvider from 'src/providers/WebsocketProvider';
import TaskIFrameProvider from 'src/providers/TaskIFrameProvider';
import ApplixirProvider from 'src/providers/ApplixirProvider';
import WebsocketUserMessages from 'src/providers/WebsocketUserMessages';
import GoogleAuthProvider from 'src/providers/GoogleAuthProvider';
import ExtraScriptsProvider from 'src/providers/ExtraScriptsProvider';
import ObserveLocationProvider from 'src/providers/ObserveLocationProvider';
import EmailVerificationProvider from 'src/providers/EmailVerificationProvider';
import FeaturesProvider from 'src/providers/FeaturesProvider';
import PostRegProvider from 'src/providers/PostRegProvider';
import UpdateMainStateMessagesProvider from 'src/providers/UpdateMainStateProvider';
import usePopupV2 from 'src/hooks/usePopupV2';

import './style.css';
import { GlobalTaskProvider } from 'src/providers/GlobalTasksProvider';
import { useEffect } from 'react';
import {
  addGTMFrame,
  init as initGTM,
  registerGTMUser,
} from 'src/services/GoogleTagManagerService';
import { useProfileState } from 'src/providers/ProfileProvider';
import InAppOverlay from 'src/components/InAppOverlay/InAppOverlay';
import InterstitialProvider from 'src/providers/InterstitialProvider';

const AppDynamic = () => {
  const { profile } = useProfileState();
  const isAuth = !!profile;

  usePopupV2();

  useEffect(() => {
    addGTMFrame();
    initGTM();

    if (isAuth && profile?.id) {
      registerGTMUser(profile?.id);
    }
  }, [isAuth]);
  return (
    <WebsocketProvider>
      <FeaturesProvider />
      <TaskIFrameProvider>
        <ApplixirProvider>
          <GoogleAuthProvider>
            <Outlet />
          </GoogleAuthProvider>
        </ApplixirProvider>
      </TaskIFrameProvider>
      <ExtraScriptsProvider />
      <WebsocketUserMessages />
      <EmailVerificationProvider />
      <PostRegProvider />
      <ObserveLocationProvider />
      <UpdateMainStateMessagesProvider />
      <GlobalTaskProvider />
      <InterstitialProvider />
    </WebsocketProvider>
  );
};

export default AppDynamic;
