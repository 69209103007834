import { FunctionComponent, PropsWithChildren } from 'react';
import RootButton from '@mui/material/Button';

type Props = PropsWithChildren & {
  onClick: () => void;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  variant?: 'outlined' | 'contained';
  size?: 'small' | 'large';
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

const Button: FunctionComponent<Props> = ({
  onClick,
  color,
  variant,
  size,
  fullWidth,
  children,
  disabled,
  loading,
}) => {
  return (
    <RootButton
      onClick={onClick}
      color={color}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {children}
    </RootButton>
  );
};

export default Button;
