import { makeStyles } from '@griffel/react';

export const useHeaderBackStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    '@media(min-width: 800px)': {
      display: 'none',
    },
  },
  backButton: {
    position: 'absolute',
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
  },
  content: {
    padding: '0 64px',
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: '18px',
    lineHeight: '24px',
    margin: '0 auto',
  },
});
