import { makeStyles } from '@griffel/react';

export const useArticleListStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '16px',
  },
});

export const useArticleListItemStyles = makeStyles({
  root: {
    padding: '8px',
    borderRadius: '8px',
    background: 'white',
    display: 'flex',
    width: '300px',
  },
  img: {
    width: '30%',
    minWidth: '100px',
    height: '80px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  desc: {
    paddingLeft: '16px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer',
  },
  preview: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    fontSize: '12px',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  readMore: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    textDecoration: 'underline',
    color: '#3D25D2',
    cursor: 'pointer',
  },
});

export const useArticleDetailStyles = makeStyles({
  root: {
    color: 'white',
  },
  title: {
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '16px',
  },
  text: {},
  subTitle: {
    fontSize: '18px',
  },
  paragraph: {
    margin: '16px',
    fontSize: '14px',
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },
});
