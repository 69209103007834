import { atom, selector } from 'recoil';

type State = {
  symbols: string[];
  winMatrix: number[][];
  symbolsMatrix: string[][];
  win: number;
  balance: number;
};

export const slotMachineAtom = atom<State>({
  key: 'slot-machine-data',
  default: {
    symbols: [],
    win: 0,
    winMatrix: [],
    symbolsMatrix: [],
    balance: 0,
  },
});

export const slotMachinePartialSelector = selector<Partial<State>>({
  key: 'slot-machine-partial',
  set: ({ set, get }, partialNewValue) => {
    const state = get(slotMachineAtom);

    set(slotMachineAtom, { ...state, ...partialNewValue });
  },
  get: ({ get }) => {
    return get(slotMachineAtom);
  },
});
