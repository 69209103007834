import React, { useEffect, useMemo } from 'react';

import Button from 'src/components/buttons/Button';
import EarnTicketButton from 'src/components/buttons/EarnTicketButton';
import { useProfileState } from 'src/providers/ProfileProvider';
import Balance from './Balance';

import { ReactComponent as TicketIcon } from 'src/assets/ticket-green.svg';
import { ReactComponent as PlusIcon } from 'src/assets/slot/plus.svg';
import { ReactComponent as MinusIcon } from 'src/assets/slot/minus.svg';

import { useSlotButtonsStyles } from './get-styles';
import { useIntl } from 'react-intl';

type ButtonContainerType = {
  startSlotMachine: () => unknown;
  changeBet: (diff: number) => void;
  isPlaying: boolean;
  bet: number;
};
export const MAX_BET = 15;

const Control = ({
  startSlotMachine,
  changeBet,
  isPlaying,
  bet,
}: ButtonContainerType) => {
  const styles = useSlotButtonsStyles();
  const { profile } = useProfileState();
  const balance = profile?.balance as number;
  const intl = useIntl();

  useEffect(() => {
    if (isPlaying) {
      return;
    }
    if (bet > balance) {
      changeBet(-(bet - balance));
      return;
    }
    if (bet === 0 && balance !== 0) {
      changeBet(1);
    }
  }, [balance, isPlaying]);

  const emptyBalance = !isPlaying && balance === 0;
  const max = (balance as number) < MAX_BET ? balance : MAX_BET;

  return (
    <div className={styles.container}>
      <Balance value={balance} />
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.betTitle}>
          {intl.formatMessage({
            id: 'slot_machine.bet',
            defaultMessage: 'Your bet',
          })}
        </div>
        <div className={styles.control}>
          <button
            onClick={() => changeBet(-1)}
            className={styles.controlButton}
            disabled={isPlaying || bet <= 1}
          >
            <MinusIcon />
          </button>
          <div className={styles.betContainer}>
            <TicketIcon width="40" height="40" />
            <div className={styles.betValue}>
              <span>{bet}</span>
            </div>
          </div>
          <button
            onClick={() => changeBet(1)}
            className={styles.controlButton}
            disabled={isPlaying || bet >= max}
          >
            <PlusIcon />
          </button>
        </div>
        {emptyBalance ? (
          <EarnTicketButton container="slot-machine" />
        ) : (
          <Button
            onClick={startSlotMachine}
            color="success"
            variant="contained"
            size="large"
            disabled={isPlaying}
            fullWidth
          >
            {intl.formatMessage({
              id: 'slot_machine.play',
              defaultMessage: 'Play',
            })}
          </Button>
        )}
      </form>
    </div>
  );
};

export default Control;
