import { FunctionComponent, PropsWithChildren } from 'react';
import { useHeaderBackStyles } from 'src/components/Header/get-styles';

import { ReactComponent as ArrowBackIcon } from 'src/assets/arrow_back.svg';
import Button from 'src/components/buttons/Button';
import { useNavigate } from 'react-router-dom';

type Props = PropsWithChildren;

const HeaderBack: FunctionComponent<Props> = ({ children }) => {
  const styles = useHeaderBackStyles();
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div className={styles.backButton}>
        <Button onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </Button>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default HeaderBack;
