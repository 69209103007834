export enum BROWSER_NAME {
  SAFARI = 'safari',
  MESSENGER = 'messenger',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINE = 'line',
  WECHAT = 'wechat',
  PUFFIN = 'puffin',
  MIUI = 'miui',
  INSTAGRAM = 'instagram',
  CHROME = 'chrome',
  IE = 'ie',
  FIREFOX = 'firefox',
}

export const BROWSER: { [key: string]: RegExp } = {
  [BROWSER_NAME.MESSENGER]: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  [BROWSER_NAME.FACEBOOK]: /\bFB[\w_]+\//,
  [BROWSER_NAME.TWITTER]: /\bTwitter/i,
  [BROWSER_NAME.LINE]: /\bLine\//i,
  [BROWSER_NAME.WECHAT]: /\bMicroMessenger\//i,
  [BROWSER_NAME.PUFFIN]: /\bPuffin/i,
  [BROWSER_NAME.MIUI]: /\bMiuiBrowser\//i,
  [BROWSER_NAME.INSTAGRAM]: /\bInstagram/i,
  [BROWSER_NAME.CHROME]: /\bCrMo\b|CriOS|Android.*Chrome\/[.0-9]* (Mobile)?/,
  [BROWSER_NAME.SAFARI]:
    /\bSafari\/[.0-9]*\b(?!.*Chrome|CriOS|CrMo|Edge|OPR|Edg|EdgiOS)/i,
  [BROWSER_NAME.IE]: /IEMobile|MSIEMobile/,
  [BROWSER_NAME.FIREFOX]:
    /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
};
