import { FunctionComponent } from 'react';
import { useGameListItemStyles } from 'src/components/GameList/get-styles';
import SlotImg from 'src/assets/slot-small.png';
import { ROUTE_SLOT_MACHINE } from 'src/constants/routes';
import { FEATURE } from 'src/constants/enums';
import { useIntl } from 'react-intl';
import Button from 'src/components/buttons/Button';
import { useNavigate } from 'react-router-dom';

export type GameData = {
  name: {
    id: string;
    defaultMessage: string;
  };
  img: string;
  route: string;
  feature: FEATURE;
  new?: boolean;
};

type Props = {
  data: GameData;
};

const GameListItem: FunctionComponent<Props> = ({ data }) => {
  const styles = useGameListItemStyles();
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div className={styles.img}>
        <img src={data.img} />
      </div>
      <div className={styles.description}>
        <div className={styles.title}>
          <div>{intl.formatMessage(data.name)}</div>
          {data.new && (
            <div className={styles.badge}>
              {intl.formatMessage({
                id: 'badge.new',
                defaultMessage: 'New',
              })}
            </div>
          )}
        </div>
        <Button
          onClick={() => navigate(data.route)}
          color="success"
          variant="contained"
          size="small"
        >
          {intl.formatMessage({
            id: 'games.play',
            defaultMessage: 'Play',
          })}
        </Button>
      </div>
    </div>
  );
};

export default GameListItem;
