import { FunctionComponent } from 'react';
import { useArticleDetailStyles } from 'src/components/articles/get-styles';

export type ArticleData = {
  story: string;
  text: { title: string; paragraph: string[] }[];
};

type Props = {
  article: ArticleData;
  id: string;
};

const ArticleDetail: FunctionComponent<Props> = ({ article, id }) => {
  const classes = useArticleDetailStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>{article.story}</div>
      <div>
        {article.text.map(({ title, paragraph }, i) => (
          <div className={classes.text} key={i}>
            <div>
              <div className={classes.subTitle}>{title}</div>
              {paragraph.map((p, i) => (
                <div key={i} className={classes.paragraph}>
                  {p}
                </div>
              ))}
            </div>
            {i === 0 && (
              <div className={classes.img}>
                <img src={`/articles/${id}-1.jpg`} alt="img" height="140px" />
              </div>
            )}
            {i === article.text.length - 1 && (
              <div className={classes.img}>
                <img src={`/articles/${id}-2.jpg`} alt="img" height="140px" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleDetail;
