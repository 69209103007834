import { makeStyles } from '@griffel/react';

export const useGameListStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    maxWidth: '400px',
    padding: '0 16px',
    marginTop: '16px',
  },
});

export const useGameListItemStyles = makeStyles({
  root: {
    borderRadius: '8px',
    background: '#1D1F34',
    padding: '0 8px',
    width: '100%',
    color: '#FFFFFF',
    display: 'flex',
    gap: '18px',
  },
  img: {
    width: '90px',
    height: '90px',
    display: 'flex',
    '& img': {
      margin: 'auto',
    },
  },
  description: {
    padding: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '16px',
    lineHeight: '16px',
    display: 'flex',
    gap: '12px',
  },
  badge: {
    width: '40px',
    height: '16px',
    lineHeight: '16px',
    fontSize: '14px',
    background: '#FFFFFF',
    color: '#11B479',
    borderRadius: '8px',
    textAlign: 'center',
    fontWeight: 500,
  },
});
