import { useMemo } from 'react';

import { ROUTE_FORTUNE, ROUTE_SLOT_MACHINE } from 'src/constants/routes';
import { useFeatureCallback } from 'src/providers/FeaturesProvider';
import GameListItem, { GameData } from './GameListItem';
import { FEATURE } from 'src/constants/enums';

import WheelImg from 'src/assets/wheel-small.png';
import SlotImg from 'src/assets/slot-small.png';
import { useGameListStyles } from 'src/components/GameList/get-styles';

const basicGames: GameData[] = [
  {
    name: {
      id: 'fortune',
      defaultMessage: 'Wheel of fortune',
    },
    img: WheelImg,
    route: ROUTE_FORTUNE,
    feature: FEATURE.WHEEL_OF_FORTUNE,
  },
  {
    name: {
      id: 'slot',
      defaultMessage: 'Slot machine',
    },
    img: SlotImg,
    route: ROUTE_SLOT_MACHINE,
    feature: FEATURE.SLOT_MACHINE,
    new: true,
  },
];

const GameList = () => {
  const featureCallback = useFeatureCallback();
  const styles = useGameListStyles();

  const games = useMemo(() => {
    return basicGames.filter(({ feature }) => featureCallback(feature));
  }, [featureCallback]);

  return (
    <div className={styles.root}>
      {games.map((game) => (
        <GameListItem data={game} />
      ))}
    </div>
  );
};

export default GameList;
