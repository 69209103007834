import { FunctionComponent, PropsWithChildren } from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { rewardisLandingTheme, landingNewTheme } from 'src/themes';
import DebugPanel from 'src/components/DebugPanel';
import SwitchLanguage from 'src/components/SwitchLanguage';
import Footer from 'src/layouts/_MainFooter';
import CookieConsent from 'src/components/CookieConsent';

import { ReactComponent as Logo } from 'src/assets/logo-desktop.svg';
import { ReactComponent as LogoMobile } from 'src/assets/logo-mobile.svg';
import { useDesignParams } from 'src/hooks/useDesign';
import { DESIGN_ID, GREEN_LANDING_DESIGN_ID } from 'src/constants/landings';

const LayoutLanding: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { designId } = useDesignParams();
  const theme = GREEN_LANDING_DESIGN_ID.includes(designId)
    ? landingNewTheme
    : rewardisLandingTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DebugPanel />
      <Box
        sx={(theme) => ({
          background: '#07091D',
          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
          },
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
      >
        <Container
          component="header"
          sx={(theme) => ({
            paddingY: 3,
            width: '100%',
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
              justifyContent: 'space-between',
              paddingY: 2,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              width: '63px',
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
          ></Box>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
          >
            <Logo />
          </Box>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            })}
          >
            <LogoMobile />
          </Box>
          <Box
            sx={(theme) => ({
              top: '24px',
              right: '24px',
              display: 'inline-flex',
              '& button': {
                margin: 'auto',
              },
              [theme.breakpoints.up('md')]: {
                height: '32px',
              },
            })}
          >
            <SwitchLanguage isRedesigned />
          </Box>
        </Container>
        {children}
        <Footer />
      </Box>
      <CookieConsent />
    </ThemeProvider>
  );
};

export default LayoutLanding;
