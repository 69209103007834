import GameList from 'src/components/GameList/GameList';
import EarningUserStream from 'src/components/EarningUserStream';
import Container from '@mui/material/Container';

const GamesPage = () => {
  return (
    <>
      <Container>
        <EarningUserStream source="earning-user-stream" />
      </Container>
      <GameList />
    </>
  );
};

export default GamesPage;
