import { useCaptchaMutation } from 'src/recoil/recaptcha';
import { useReCaptchaContext } from 'src/providers/ReCaptchaProvider';
import { useEffect, useState } from 'react';
import {
  renderCaptcha,
  resetCaptchaWidget,
} from 'src/providers/CloudflareCaptchaProvider/CloudflareCaptchaProvider';

export const useResetWidget = () => {
  const mutateRecaptcha = useCaptchaMutation();
  const { meta } = useReCaptchaContext();

  return () => {
    if (meta?.widgetId) {
      mutateRecaptcha({
        meta: {
          widgetId: meta.widgetId,
          attempt: meta.attempt + 1,
        },
      });
    }
  };
};

export const useCloudflareWidget = (
  containerId: string,
  callback: (token: string) => void
) => {
  const mutateRecaptcha = useCaptchaMutation();
  const { loaded, meta } = useReCaptchaContext();
  const [isVisible, setVisible] = useState(false);

  const setWidgetVisible = () => {
    setVisible(true);
  };

  const installWidget = () => {
    const widgetId = renderCaptcha(containerId, callback, setWidgetVisible);
    console.debug('widget id', widgetId);

    mutateRecaptcha({
      meta: {
        widgetId,
        attempt: 0,
      },
    });
  };

  useEffect(() => {
    if (!loaded) return;

    installWidget();
  }, [loaded]);

  useEffect(() => {
    if (meta?.attempt && meta.widgetId) {
      resetCaptchaWidget(meta.widgetId);
    }
  }, [meta?.attempt]);

  useEffect(() => {
    return () => {
      mutateRecaptcha({ loaded: false, readyToInit: false });
    };
  }, []);

  return {
    isVisible,
  };
};
