import { useState } from 'react';
import { useIFrameTaskDispatch } from 'src/providers/TaskIFrameProvider';
import { useApplixirDispatch } from 'src/providers/ApplixirProvider';
import { useProfileState } from 'src/providers/ProfileProvider';
import { TASK_NAME, TASK_TYPE } from 'src/constants/enums';
import { initNotix } from 'src/hooks/extraScripts/usePushSubscription';
import { extractSubscriptionParams } from 'src/hooks/useTasks';
import * as OffersService from 'src/services/OffersService';
import { isSuccess } from 'src/lib/remoteData';
import * as Sentry from '@sentry/react';

export enum EXTERN_TASK {
  SAM,
  CPX,
  YOUTUBE,
}

const addParam = (originUrl: string, paramName: string, paramValue: string) => {
  const url = new URL(originUrl);

  url.searchParams.append(paramName, paramValue);

  return url;
};

const useCurrentTask = () => {
  const [current, setCurrent] = useState<{
    type: EXTERN_TASK;
    task: Task;
  } | null>(null);
  const { setTask: setIFrameTask } = useIFrameTaskDispatch();
  const { setTask: setApplixirTask } = useApplixirDispatch();
  const { profile } = useProfileState();

  const clear = () => {
    setCurrent(null);
  };
  const updateNoRedirect = async (id: number, source: string) => {
    const request = await OffersService.getSubID(
      Number(profile?.id),
      id,
      source
    );

    return isSuccess(request);
  };

  const apply = async (task: Task, source: string) => {
    if (task.name === TASK_NAME.SAM) {
      setCurrent({ type: EXTERN_TASK.SAM, task });
      return false;
    }
    if (task.name === TASK_NAME.CPX) {
      setCurrent({ type: EXTERN_TASK.CPX, task });
      return false;
    }
    if (task.type === TASK_TYPE.YOUTUBE) {
      setCurrent({ type: EXTERN_TASK.YOUTUBE, task });
      return false;
    }
    if (task.name == TASK_NAME.APLIXIR && profile) {
      setApplixirTask(task);
      return false;
    }
    if (task.type && (task.type as TASK_TYPE) === TASK_TYPE.NO_REDIRECT) {
      return await updateNoRedirect(task.id, source);
    }
    if (task.is_iframe) {
      setIFrameTask(task);
      return false;
    }
    if (task.name == TASK_NAME.SUBSCRIPTION && profile) {
      await initNotix(
        Number(profile.id),
        task.id,
        extractSubscriptionParams(task)
      );
      return false;
    }
    if (task.url) {
      try {
        const w = window.open(addParam(task.url, 'route', source), '_blank');
        if (w) {
          w.focus();
        }
      } catch (e) {
        Sentry.captureException({ message: 'wrong task url' }, (scope) => {
          scope.setExtra('data', {
            url: task.url,
            taskId: task.id,
          });

          return scope;
        });
      }

      return task.type !== TASK_TYPE.OWN_SURVEY;
    }

    return false;
  };

  return {
    current,
    apply,
    clear,
  };
};

export default useCurrentTask;
