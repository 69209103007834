function randomInt(min: number, max: number): number {
  if (!min && !max && max !== 0) {
    return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
  }

  // max included
  return Math.floor(Math.random() * (max + 1 - min) + min);
}

export default randomInt;
