import { useState, useCallback } from 'react';
import {
  failure,
  notAsked,
  RemoteData,
  RemoteDataFailure,
  RemoteDataLoading,
  RemoteDataNotAsked,
  RemoteDataResult,
  RemoteDataSuccess,
  Status,
  success,
} from 'src/lib/remoteData';

export function useRequestSimple<R = any, S = any, F = any>(
  apiCall: (data: R) => Promise<RemoteDataResult<S, F>>
): {
  data:
    | RemoteDataNotAsked
    | RemoteDataLoading
    | RemoteDataSuccess<S>
    | RemoteDataFailure<F>;
  isLoading: boolean;
  makeRequest: (requestData?: R) => Promise<RemoteData<S, F>>;
} {
  const [data, setData] = useState<RemoteData<S, F>>(notAsked);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const makeRequest = useCallback(
    async (requestData?: R): Promise<RemoteData<S, F>> => {
      try {
        setIsLoading(true);
        setData(notAsked);
        const result = await apiCall(requestData!);
        setIsLoading(false);

        let response;
        if (result.status === Status.Success) {
          response = success(result.data) as RemoteDataSuccess<S>;
          setData(response);
        } else {
          response = failure(result.error) as RemoteDataFailure<F>;
          setData(failure(result.error) as RemoteDataFailure<F>);
        }

        return response;
      } catch (err) {
        setData(failure(err as F) as RemoteDataFailure<F>);
        setIsLoading(false);
        return failure(err) as RemoteDataFailure<F>;
      }
    },
    [apiCall]
  );

  return { data, isLoading, makeRequest };
}
