import HeaderBack from 'src/components/Header/HeaderBack';
import SlotMachine from 'src/components/SlotMachine/SlotMachine';
import { useIntl } from 'react-intl';
import { useSlotMachinePageStyles } from 'src/pages/games/get-styles';

const SlotMachinePage = () => {
  const intl = useIntl();
  const styles = useSlotMachinePageStyles();

  return (
    <div id="slot-machine-container" className={styles.container}>
      <HeaderBack>
        {intl.formatMessage({
          id: 'slot_machine.title',
          defaultMessage: 'Slot machine',
        })}
      </HeaderBack>
      <SlotMachine />
    </div>
  );
};

export default SlotMachinePage;
