import { FunctionComponent, useEffect, useState } from 'react';
import useCurrentTask from 'src/hooks/useTask';
import { useNavigate } from 'react-router-dom';
import * as OffersService from 'src/services/OffersService';
import { isSuccess } from 'src/lib/remoteData';
import { ROUTE_TASKS } from 'src/constants/routes';
import Modal from 'src/components/Modal';
import Box from '@mui/material/Box';
import Loader from 'src/components/Loader';
import { TaskModalContent } from 'src/components/Tasks/TaskModal/taskModalContent';

export type TaskRequestModalProps = {
  source: string;
  offerId: number;
  onClose?: () => void;
};

export const TaskRequestModal: FunctionComponent<TaskRequestModalProps> = (
  props
) => {
  const { current, apply, clear } = useCurrentTask();

  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { offerId, source } = props;

  useEffect(() => {
    if (!offerId) {
      return;
    }

    (async () => {
      setLoading(true);

      const response = await OffersService.getOfferDetails(offerId);

      setLoading(false);

      if (!isSuccess(response)) {
        navigate(ROUTE_TASKS);
        return;
      }

      if (!response.data) {
        props?.onClose?.();
        navigate(ROUTE_TASKS);
        return;
      }

      await apply(response.data, source);
    })();
  }, [offerId]);

  if (!offerId) {
    return null;
  }

  const handleClose = () => {
    props?.onClose?.();
    clear();
  };

  return (
    <Modal noPadding withClose isOpen handleClose={handleClose}>
      {isLoading ? (
        <Box sx={{ marginY: 3 }}>
          <Loader />
        </Box>
      ) : null}
      {current ? (
        <TaskModalContent
          task={current.task}
          applyTask={async (source: string, task: Task) => {
            await apply(task, source);
            props?.onClose?.();
          }}
          source={source}
        />
      ) : null}
    </Modal>
  );
};
