import { useRecoilState, useRecoilValue } from 'recoil';
import { slotMachineAtom } from 'src/recoil/slotMachine/slotMachine';
import {
  machineProcessSelector,
  SLOT_MACHINE_PROCESS,
  slotProcessSelector,
} from 'src/recoil/slotMachine/slotMachineProcess';
import randomInt from 'src/utils/math';
import { useEffect } from 'react';

const getIndexFromMatrix = (index: [number, number], size = 3) => {
  return index[0] * size + index[1];
};

const SHOW_WIN_PROCESS = [
  SLOT_MACHINE_PROCESS.CHECKING,
  SLOT_MACHINE_PROCESS.EMPTY,
];

const useSlot = (index: [number, number]) => {
  const state = useRecoilValue(slotMachineAtom);
  const process = useRecoilValue(machineProcessSelector);
  const [isRunning, setRunning] = useRecoilState(
    slotProcessSelector(getIndexFromMatrix(index))
  );

  useEffect(() => {
    if (process === SLOT_MACHINE_PROCESS.PLAYING) {
      setRunning(true);
    }
    if (process === SLOT_MACHINE_PROCESS.FINISHING) {
      const timeoutId = setTimeout(
        () => {
          setRunning(false);
        },
        randomInt(300, 1000)
      );

      return () => clearTimeout(timeoutId);
    }
  }, [process]);

  return {
    getSymbol: () => {
      const { symbolsMatrix, symbols } = state;

      if (symbolsMatrix.length && !isRunning) {
        return symbolsMatrix[index[0]][index[1]];
      }

      return symbols[randomInt(0, symbols.length - 1)];
    },
    isWinner: () => {
      if (state.winMatrix.length) {
        return !!state.winMatrix[index[0]][index[1]];
      }

      return false;
    },
    isRunning,
    hasWinners: () => {
      return SHOW_WIN_PROCESS.includes(process) && state.win !== 0;
    },
  };
};

export default useSlot;
