import React, { useState } from 'react';

import Control, { MAX_BET } from './Control';
import SlotContainer from './SlotContainer';
import useSlotMachine from './useSlotMachine';
import { useSlotMachineStyles } from './get-styles';
import WinModal from 'src/components/SlotMachine/WinModal';
import Loader from 'src/components/Loader';

const SlotMachine = () => {
  const [bet, setBet] = useState(1);
  const { play, isLoaded, isPlaying, win, cleanProcess } = useSlotMachine();
  const styles = useSlotMachineStyles();

  const startMachine = () => {
    play(bet);
  };

  const changeBet = (diff: number) => {
    setBet((prev) => {
      const newBet = prev + diff;

      return newBet > MAX_BET ? prev : newBet;
    });
  };

  return isLoaded ? (
    <div className={styles.container}>
      <div className={styles.container}>
        <SlotContainer />
        <Control
          startSlotMachine={startMachine}
          changeBet={changeBet}
          isPlaying={isPlaying}
          bet={bet}
        />
      </div>
      {Boolean(win) && <WinModal value={win} onClose={cleanProcess} />}
    </div>
  ) : (
    <Loader fullSize />
  );
};

export default SlotMachine;
