import { FunctionComponent, useEffect } from 'react';
import { Outlet } from 'react-router';
import PrizeBlock from './PrizeBlock';
import { RegPrizeService } from 'src/services/RegFlowService';
import { useDesignParams } from 'src/hooks/useDesign';
import LandingBlock from 'src/pages/landing/LandingBlock';

const LandingPage: FunctionComponent<{
  icon?: string;
  image?: string;
  isNewDesign?: boolean;
}> = ({ icon, image, isNewDesign }) => {
  const { offerId } = useDesignParams();

  useEffect(() => {
    RegPrizeService.setState({
      icon,
      image,
      isNewDesign,
      offerId,
    });
  }, []);

  return (
    <>
      <PrizeBlock image={image} icon={icon} isNewDesign={isNewDesign}>
        <Outlet />
      </PrizeBlock>

      <LandingBlock isNewDesign={isNewDesign} />
    </>
  );
};

export default LandingPage;
