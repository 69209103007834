import React, { FunctionComponent, useEffect, useState } from 'react';
import useSlot from './useSlot';

import { useSlotStyles } from './get-styles';

type Props = {
  timeInterval: number;
  index: [number, number];
};

const Slot: FunctionComponent<Props> = ({ timeInterval, index }: any) => {
  const { getSymbol, isWinner, isRunning, hasWinners } = useSlot(index);
  const [currentSymbolIndex, setCurrentSymbolIndex] = useState(getSymbol());
  const styles = useSlotStyles();

  useEffect(() => {
    if (!isRunning) {
      setCurrentSymbolIndex(getSymbol());
      return;
    }

    const interval = setInterval(() => {
      setCurrentSymbolIndex(getSymbol());
    }, timeInterval);

    return () => clearInterval(interval);
  }, [isRunning]);

  return (
    <div
      className={styles.root}
      style={{
        opacity: hasWinners() ? (isWinner() ? 1 : 0.3) : 1,
      }}
    >
      <img
        src={`/slots/v2-slot-${currentSymbolIndex}.png`}
        alt="image"
        className={styles.img}
      />
    </div>
  );
};

export default Slot;
