import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FEATURE } from 'src/constants/enums';
import { useProfileState } from 'src/providers/ProfileProvider';
import {
  featuresGetIsLoaded,
  featuresGetFeatures,
  featuresAtom,
} from 'src/recoil/features';
import { registrationParamsState } from 'src/recoil/registrationParams';

export const useFeature = <T extends FEATURE>(name: T) => {
  const features = useRecoilValue(featuresGetFeatures);

  return Object.keys(features?.[name] || {}).length
    ? features?.[name]
    : undefined;
};

export const useFeatureCallback = () => {
  const features = useRecoilValue(featuresGetFeatures) ?? {};

  return useCallback(
    <T extends FEATURE>(feature: T | T[]) => {
      if (Array.isArray(feature)) {
        return feature.some((f) => features[f]);
      }

      return features ? features[feature] : undefined;
    },
    [features]
  );
};

export const useFeatureLoadingState = () => {
  return useRecoilValue(featuresGetIsLoaded);
};

const useUpdateFeatures = () => {
  const setFeaturesState = useSetRecoilState(featuresAtom);
  const { profile } = useProfileState();
  const [regParams] = useRecoilState(registrationParamsState);
  const geo = profile?.geo || regParams?.geo;

  // TODO: this is temp solution
  return (features: UserFeatures) => {
    const safeFeatures = typeof features === 'object' ? features : {};
    const featuresWithEnabledGeoExcludes = !geo
      ? safeFeatures
      : Object.entries(safeFeatures)
          .filter(
            ([_, value]) =>
              // @ts-ignore
              !value?.['excludes_geos'] ||
              // @ts-ignore
              !value['excludes_geos'].includes(geo)
          )
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    setFeaturesState({
      isLoaded: true,
      features: featuresWithEnabledGeoExcludes,
    });
  };
};

export const useFeaturesUpdater = () => {
  const updateFeatures = useUpdateFeatures();

  return (features: UserFeatures) => {
    updateFeatures(features);
  };
};

const FeaturesProvider = () => {
  const { profile } = useProfileState();
  const updateFeatures = useUpdateFeatures();

  useEffect(() => {
    if (profile) {
      updateFeatures(profile.features);
    }
    if (!profile) {
      updateFeatures({});
    }
  }, [profile]);

  return null;
};

export default FeaturesProvider;
