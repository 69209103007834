import { atom, selector, selectorFamily } from 'recoil';

export enum SLOT_MACHINE_PROCESS {
  EMPTY,
  PLAYING,
  FINISHING,
  CHECKING,
}

const defaultSlotProcess = Array(9).fill(false);

export const slotMachineProcessAtom = atom({
  key: 'slot-machine-process',
  default: {
    process: SLOT_MACHINE_PROCESS.EMPTY,
    slotProcess: defaultSlotProcess,
  },
});

export const slotProcessSelector = selectorFamily<boolean, number>({
  key: 'slot-machine-slot-process',
  get:
    (index) =>
    ({ get }) => {
      const { slotProcess } = get(slotMachineProcessAtom);

      return slotProcess[index];
    },
  set:
    (index) =>
    ({ set, get }, newValue) => {
      const { slotProcess, process } = get(slotMachineProcessAtom);
      const _slotProcess = [...slotProcess];
      _slotProcess[index] = newValue;

      const _process =
        process === SLOT_MACHINE_PROCESS.FINISHING &&
        !_slotProcess.some((slot) => slot)
          ? SLOT_MACHINE_PROCESS.CHECKING
          : process;

      set(slotMachineProcessAtom, {
        process: _process,
        slotProcess: _slotProcess,
      });
    },
});

export const machineProcessSelector = selector<SLOT_MACHINE_PROCESS>({
  key: 'slot-machine-process-process',
  get: ({ get }) => {
    return get(slotMachineProcessAtom).process;
  },
  set: ({ get, set }, newValue) => {
    const state = get(slotMachineProcessAtom);
    const _slotProcess =
      newValue === SLOT_MACHINE_PROCESS.EMPTY
        ? defaultSlotProcess
        : state.slotProcess;

    set(slotMachineProcessAtom, {
      slotProcess: _slotProcess,
      process: newValue as SLOT_MACHINE_PROCESS,
    });
  },
});
